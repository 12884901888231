import { takeEvery, put, call, fork, all } from "redux-saga/effects";

import { Client } from "../../utils/client";
import { SUBSCRIPTIONS_LOAD, subscriptionsLoadSuccess } from "./actions";
import { setError } from "../../redux-root/actions";

export function* subscriptionsLoad() {
  try {
    let profileUrl = '/me';

    if (window.tizen) {
      profileUrl = '/me?platformtype=Tizen';
    }

    const [profileData, partnerApps] = yield all([
      call(Client.getData, profileUrl),
      call(Client.getData, '/me/partnerapps')
    ]);

    yield put(subscriptionsLoadSuccess(profileData.data, partnerApps.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchSubscriptionsLoad() {
  yield takeEvery(SUBSCRIPTIONS_LOAD, subscriptionsLoad);
}

export function* subscriptionsSaga() {
  yield fork(watchSubscriptionsLoad);
}
