import { ABOUTUS_LOAD, ABOUTUS_LOAD_SUCCESS } from "./actions";

const initialState = {
  loaded: false
};

export function aboutUsReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case ABOUTUS_LOAD:
      return {
        ...state,
      };

    case ABOUTUS_LOAD_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
