import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { searchSuccess, SEARCH_LOAD, SEARCH_PAGE_LOAD, searchPageLoadSuccess, SEARCH_HISTORY_ADD, searchHistoryActionSuccess, SEARCH_HISTORY_CLEAR, searchHistoryClearSuccess } from './actions';
import { setError } from '../../redux-root/actions';

export function* searchApi(action) {
  const { payload } = action;
  try {
    const searchData = yield call(Client.getData, '/search?q=' + payload);

    yield put(searchSuccess(searchData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* searchPageLoad() {
  try {
    let searchHistoryData = [];
    let historyData = {};
    let searchData = {};

    if (localStorage.getItem('_r')) {
      [searchData, historyData] = yield all([
        call(Client.getData, '/search/top'),
        call(Client.getData, '/me/history/searches')
      ]);

      searchHistoryData = historyData.data;
    } else {
      searchData = yield call(Client.getData, '/search/top');
    }
    yield put(searchPageLoadSuccess(searchData.data, searchHistoryData));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* searchHistoryAdd(action) {
  try {
    const searchRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      term: action.term,
      targetType: action.contentType,
      target: action.id
    };

    yield call(Client.postData, '/me/history/searches', searchRequest);

    yield put(searchHistoryActionSuccess());
  } catch (error) {
    console.log(error);

    // yield put(setError('Search Page Title', 'Search Page error'));
  }
}

export function* searchHistoryClear(action) {
  try {
    yield call(Client.deleteData, '/me/history/searches');

    yield put(searchHistoryClearSuccess());
  } catch (error) {
    console.log(error);

    // yield put(setError('Search Page Title', 'Search Page error'));
  }
}

function* watchSearch() {
  yield takeEvery(SEARCH_LOAD, searchApi);
}

function* watchSearchPage() {
  yield takeEvery(SEARCH_PAGE_LOAD, searchPageLoad);
}

function* watchSearchHistoryAdd() {
  yield takeEvery(SEARCH_HISTORY_ADD, searchHistoryAdd);
}

function* watchSearchHistoryClear() {
  yield takeEvery(SEARCH_HISTORY_CLEAR, searchHistoryClear);
}

export function* searchSaga() {
  yield fork(watchSearch);
  yield fork(watchSearchPage);
  yield fork(watchSearchHistoryAdd);
  yield fork(watchSearchHistoryClear);
}
