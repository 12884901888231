export const HOME_LOAD = 'home/on_load';
export const homeLoad = (profile) => ({
  type: HOME_LOAD,
  profileData: profile
});

export const HOME_LOAD_SUCCESS = 'home/on_load_success';
export const homeLoadSuccess = (data, favourite) => ({
  type: HOME_LOAD_SUCCESS,
  payload: data,
  favourite: favourite
});