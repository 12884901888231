export const FAVOURITE_LOAD = "favourite/on_load";
export const favouriteLoad = () => ({
  type: FAVOURITE_LOAD,
});

export const FAVOURITE_LOAD_SUCCESS = "favourite/on_load_success";
export const favouriteLoadSuccess = (data) => ({
  type: FAVOURITE_LOAD_SUCCESS,
  payload: data,
});

export const FAVOURITE_ADD = "favourite/add_to";
export const favouriteAdd = (data) => ({
  type: FAVOURITE_ADD,
  payload: data
});

export const FAVOURITE_REMOVE = "favourite/remove_from";
export const favouriteRemove = (data) => ({
  type: FAVOURITE_REMOVE,
  payload: data
});

export const FAVOURITE_ACTION_SUCCESS = "favourite/action_success";
export const favouriteActionSuccess = (data, bool, addAction) => ({
  type: FAVOURITE_ACTION_SUCCESS,
  payload: data,
  status: bool,
  addAction: addAction
});

export const FAVOURITE_CHANNEL_LOAD = "favourite/channel_load";
export const favouriteChannelLoad = () => ({
  type: FAVOURITE_CHANNEL_LOAD,
});

export const FAVOURITE_CHANNEL_LOAD_SUCCESS = "favourite/channel_load_success";
export const favouriteChannelLoadSuccess = (data) => ({
  type: FAVOURITE_CHANNEL_LOAD_SUCCESS,
  payload: data,
});
