export const ONDEMAND_LOAD = "ondemand/load";
export const ondemandLoad = () => ({
  type: ONDEMAND_LOAD,
});

export const ONDEMAND_LOAD_SUCCESS = "ondemand/load_success";
export const ondemandLoadSuccess = (data, pageData, favourite) => ({
  type: ONDEMAND_LOAD_SUCCESS,
  payload: data,
  pageData: pageData,
  favourite: favourite,
});

export const ONDEMAND_FILTER = "ondemand/filter";
export const ondemandFilter = (data) => ({
  type: ONDEMAND_FILTER,
  payload: data,
});

export const ONDEMAND_FILTER_SUCCESS = "ondemand/filter_success";
export const ondemandFilterSuccess = (data) => ({
  type: ONDEMAND_FILTER_SUCCESS,
  payload: data,
  // payload: data,
});
