import {
  SETUP_SEND_OTP,
  SETUP_SEND_OTP_SUCCESS,
  SETUP_SET_DETAILS,
  SETUP_SET_DETAILS_SUCCESS,
  SETUP_SET_DETAILS_ERROR
} from './actions';

const initialState = {
  otpSent: false,
  detailsSet: false,
  error: null
};

export function setupReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SETUP_SEND_OTP:
      return {
        ...state,
        otpSent: false
      };

    case SETUP_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null
      };

    case SETUP_SET_DETAILS_SUCCESS:
      return {
        ...state,
        detailsSet: true
      };

    case SETUP_SET_DETAILS_ERROR:
      return {
        ...state,
        error: data,
        otpSent: false
      };

    default:
      return state;
  }
}
