import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { GUIDE_LOAD, guideLoadSuccess, GUIDE_FILTER_DATE, guideFilterDateSuccess, GUIDE_GET_PACKAGE, guideGetPackageSuccess, GUIDE_REFRESH, guideRefreshSuccess } from './actions';
import { setError } from '../../redux-root/actions';
import epgTimeJson from '../../config/epg-time.json';

export function* guideLoad() {
  try {
    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 13, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    let epgList = [];
    let epgData = {
      data: []
    };

    const hourDiff = Math.ceil((endDate - startDate) / (1000 * 3600));
    const numberOfFiles = Math.ceil(hourDiff / 6);

    startDate.setHours(epgTimeJson.epg.find(e => startDate.getHours() >= e));

    for (let i = 0; i < numberOfFiles; i++) {
      const newEndDate = new Date(startDate);
      newEndDate.setHours(startDate.getHours() + 5, 59, 59, 999);

      epgList.push(call(Client.getData, `/channels/epg/?offset=0&limit=10000&startdate=${startDate.toISOString()}&enddate=${newEndDate.toISOString()}`));

      startDate.setHours(startDate.getHours() + 6, 0, 0, 0);
    }

    const finalEpgList = yield all(epgList);
    finalEpgList.forEach(e => {
      epgData.data = [...epgData.data, ...e.data];
    });

    const finalEpgData = epgData.data.filter(p => {
      const pStartDate = new Date(p.startDate);
      pStartDate.setHours(pStartDate.getHours() + 8);
      pStartDate.setSeconds(pStartDate.getSeconds() + p.duration);

      if (pStartDate > new Date()) {
        return p;
      }

      return false;
    });

    let favouriteData = null;

    if (localStorage.getItem('_r')) {
      const favouriteChannelData = yield call(Client.getData, '/me/playlists/handles/watchlater/channels');

      favouriteData = {
        channel: favouriteChannelData.data,
        movie: [],
        show: []
      };
    }

    yield put(guideLoadSuccess(finalEpgData, favouriteData));
  } catch (error) {
    console.log(error);

    // yield put(setError('Home Title', 'Home Message description'));
  }
}

export function* guideFilterDate(action) {
  const { payload } = action;

  try {
    const filterDate = new Date(payload);
    filterDate.setHours(0, 0, 0, 0);
    const startDate = new Date();

    let finalEpgData = [];

    if (filterDate.getTime() > startDate.getTime()) {
      const newStartDate = new Date(filterDate);
      newStartDate.setHours(newStartDate.getHours() - 13);
      const newEndDate = new Date(filterDate);
      newEndDate.setHours(23, 59, 59, 999);

      let epgList = [];
      let epgData = {
        data: []
      };

      const hourDiff = Math.ceil((newEndDate - newStartDate) / (1000 * 3600));
      const numberOfFiles = Math.ceil(hourDiff / 6);

      newStartDate.setHours(epgTimeJson.epg.find(e => newStartDate.getHours() >= e));

      for (let i = 0; i < numberOfFiles; i++) {
        const newEndDate2 = new Date(newStartDate);
        newEndDate2.setHours(newStartDate.getHours() + 5, 59, 59, 999);

        epgList.push(call(Client.getData, `/channels/epg/?offset=0&limit=10000&startdate=${newStartDate.toISOString()}&enddate=${newEndDate2.toISOString()}`));

        newStartDate.setHours(newStartDate.getHours() + 6, 0, 0, 0);
      }

      const finalEpgList = yield all(epgList);
      finalEpgList.forEach(e => {
        epgData.data = [...epgData.data, ...e.data];
      });

      finalEpgData = epgData.data.filter(p => {
        const pStartDate = new Date(p.startDate);
        pStartDate.setHours(pStartDate.getHours() + 8);
        pStartDate.setSeconds(pStartDate.getSeconds() + p.duration);

        if (pStartDate > filterDate) {
          return p;
        }

        return false;
      });
    } else {
      startDate.setHours(startDate.getHours() - 13, 0, 0, 0);
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999);

      let epgList = [];
      let epgData = {
        data: []
      };

      const hourDiff = Math.ceil((endDate - startDate) / (1000 * 3600));
      const numberOfFiles = Math.ceil(hourDiff / 6);

      startDate.setHours(epgTimeJson.epg.find(e => startDate.getHours() >= e));

      for (let i = 0; i < numberOfFiles; i++) {
        const newEndDate = new Date(startDate);
        newEndDate.setHours(startDate.getHours() + 5, 59, 59, 999);

        epgList.push(call(Client.getData, `/channels/epg/?offset=0&limit=10000&startdate=${startDate.toISOString()}&enddate=${newEndDate.toISOString()}`));

        startDate.setHours(startDate.getHours() + 6, 0, 0, 0);
      }

      const finalEpgList = yield all(epgList);
      finalEpgList.forEach(e => {
        epgData.data = [...epgData.data, ...e.data];
      });

      finalEpgData = epgData.data.filter(p => {
        const pStartDate = new Date(p.startDate);
        pStartDate.setHours(pStartDate.getHours() + 8);
        pStartDate.setSeconds(pStartDate.getSeconds() + p.duration);

        if (pStartDate > new Date()) {
          return p;
        }

        return false;
      });
    }

    yield put(guideFilterDateSuccess(finalEpgData));
  } catch (error) {
    console.log(error);

    // yield put(setError('Home Title', 'Home Message description'));
  }
}

export function* guideGetPackage(action) {
  const { payload } = action;

  try {
    const packageData = yield call(Client.getData, `/packages/?offset=0&limit=200&iscurrent=true&channelid=${payload}`);

    yield put(guideGetPackageSuccess(packageData.data));
  } catch (error) {
    console.log(error);

    // yield put(setError('Home Title', 'Home Message description'));
  }
}

export function* guideRefresh() {
  try {
    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 13, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    let epgList = [];
    let epgData = {
      data: []
    };

    const hourDiff = Math.ceil((endDate - startDate) / (1000 * 3600));
    const numberOfFiles = Math.ceil(hourDiff / 6);

    startDate.setHours(epgTimeJson.epg.find(e => startDate.getHours() >= e));

    for (let i = 0; i < numberOfFiles; i++) {
      const newEndDate = new Date(startDate);
      newEndDate.setHours(startDate.getHours() + 5, 59, 59, 999);

      epgList.push(call(Client.getData, `/channels/epg/?offset=0&limit=10000&startdate=${startDate.toISOString()}&enddate=${newEndDate.toISOString()}`));

      startDate.setHours(startDate.getHours() + 6, 0, 0, 0);
    }

    const finalEpgList = yield all(epgList);
    finalEpgList.forEach(e => {
      epgData.data = [...epgData.data, ...e.data];
    });

    const finalEpgData = epgData.data.filter(p => {
      const pStartDate = new Date(p.startDate);
      pStartDate.setHours(pStartDate.getHours() + 8);
      pStartDate.setSeconds(pStartDate.getSeconds() + p.duration);

      if (pStartDate > new Date()) {
        return p;
      }

      return false;
    });

    yield put(guideRefreshSuccess(finalEpgData));
  } catch (error) {
    console.log(error);

    // yield put(setError('Home Title', 'Home Message description'));
  }
}

function* watchGuideLoad() {
  yield takeEvery(GUIDE_LOAD, guideLoad);
}

function* watchGuideFilterDate() {
  yield takeEvery(GUIDE_FILTER_DATE, guideFilterDate);
}

function* watchGuideGetPackage() {
  yield takeEvery(GUIDE_GET_PACKAGE, guideGetPackage);
}

function* watchGuideRefresh() {
  yield takeEvery(GUIDE_REFRESH, guideRefresh);
}

export function* guideSaga() {
  yield fork(watchGuideLoad);
  yield fork(watchGuideFilterDate);
  yield fork(watchGuideGetPackage);
  yield fork(watchGuideRefresh);
}
