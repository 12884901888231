export const ABOUTUS_LOAD = "aboutUs/on_load";
export const aboutUsLoad = () => ({
    type: ABOUTUS_LOAD,
});

export const ABOUTUS_LOAD_SUCCESS = "aboutUs/on_load_success";
export const aboutUsLoadSuccess = (data) => ({
    type: ABOUTUS_LOAD_SUCCESS,
    payload: data
});


