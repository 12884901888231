import { takeEvery, put, call, fork } from "redux-saga/effects";

import { Client } from "../../utils/client";
import {
  SUBSCRIPTIONS_DETAIL_LOAD,
  subscriptionsDetailLoadSuccess,
} from "./actions";
import { setError } from "../../redux-root/actions";

export function* subscriptionsDetailLoad(action) {
  try {
    const detailsData = yield call(Client.getData, `/pages/${action.id}`);
    const { data } = detailsData;

    yield put(subscriptionsDetailLoadSuccess(data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchSubscriptionsDetailLoad() {
  yield takeEvery(SUBSCRIPTIONS_DETAIL_LOAD, subscriptionsDetailLoad);
}

export function* subscriptionsDetailSaga() {
  yield fork(watchSubscriptionsDetailLoad);
}
