import {
  SEARCH_LOAD,
  SEARCH_PAGE_LOAD,
  SEARCH_SUCCESS,
  SEARCH_PAGE_LOAD_SUCCESS,
  SEARCH_HISTORY_CLEAR_SUCCESS
} from './actions';

const initialState = {
  searchResult: null,
  pageData: null,
  searched: false,
  searchHistory: []
};

export function searchReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SEARCH_LOAD:
      return {
        ...state,
        searchResult: null,
        searchLoading: true
      };

    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResult: data,
        searched: true,
        searchLoading: false
      };

    case SEARCH_PAGE_LOAD:
      return {
        ...state,
        pageData: null,
        searchHistory: [],
        searchResult: null,
        searched: false
      };

    case SEARCH_PAGE_LOAD_SUCCESS:
      return {
        ...state,
        pageData: data,
        searchHistory: action.history
      };

    case SEARCH_HISTORY_CLEAR_SUCCESS:
      return {
        ...state,
        searchHistory: []
      }

    default:
      return state;
  }
}
