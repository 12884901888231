export const SEARCH_LOAD = 'search/search_load';
export const searchLoad = (data) => ({
  type: SEARCH_LOAD,
  payload: data
});

export const SEARCH_SUCCESS = 'search/search_success';
export const searchSuccess = (data) => ({
  type: SEARCH_SUCCESS,
  payload: data
});

export const SEARCH_PAGE_LOAD = 'search/search_page_load';
export const searchPageLoad = () => ({
  type: SEARCH_PAGE_LOAD
});

export const SEARCH_PAGE_LOAD_SUCCESS = 'search/search_page_load_success';
export const searchPageLoadSuccess = (data, history) => ({
  type: SEARCH_PAGE_LOAD_SUCCESS,
  payload: data,
  history: history
});

export const SEARCH_HISTORY_ADD = 'search/history_add';
export const searchHistoryAdd = (term, contentType, id) => ({
  type: SEARCH_HISTORY_ADD,
  term: term,
  contentType: contentType,
  id: id
});

export const SEARCH_HISTORY_ACTION_SUCCESS = 'search/history_action_success';
export const searchHistoryActionSuccess = () => ({
  type: SEARCH_HISTORY_ACTION_SUCCESS
});

export const SEARCH_HISTORY_CLEAR = 'search/history_clear';
export const searchHistoryClear = () => ({
  type: SEARCH_HISTORY_CLEAR
});

export const SEARCH_HISTORY_CLEAR_SUCCESS = 'search/history_clear_success';
export const searchHistoryClearSuccess = () => ({
  type: SEARCH_HISTORY_CLEAR_SUCCESS
});
