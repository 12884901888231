export const DR_LOAD = "dr/on_load";
export const drLoad = () => ({
    type: DR_LOAD,
});

export const DR_LOAD_SUCCESS = "dr/on_load_success";
export const drLoadSuccess = (data) => ({
    type: DR_LOAD_SUCCESS,
    payload: data
});


