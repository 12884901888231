import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { SETUP_SEND_OTP, setupSendOtpSuccess, SETUP_SET_DETAILS, setupSetDetailsSuccess, setupSetDetailsError } from './actions';
import { setError } from '../../redux-root/actions';

export function* setupSendOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'EmailVerificationCode',
      email: payload
    };

    const otpData = yield call(Client.postData, '/me/challenge', otpRequest);

    yield put(setupSendOtpSuccess());
  } catch (error) {
    // console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* setupSetDetails(action) {
  const { payload } = action;

  try {
    const detailsRequest = {
      ...payload,
      requestId: uuidv4()
    };

    const detailsData = yield call(Client.postData, '/me/email', detailsRequest);

    yield put(setupSetDetailsSuccess());
  } catch (error) {
    // console.log(error);

    yield put(setupSetDetailsError(error.data.title));
  }
}

function* watchSetupOTP() {
  yield takeEvery(SETUP_SEND_OTP, setupSendOtp);
}

function* watchSetupDetails() {
  yield takeEvery(SETUP_SET_DETAILS, setupSetDetails);
}

export function* setupSaga() {
  yield fork(watchSetupOTP);
  yield fork(watchSetupDetails);
}
