import { takeEvery, put, call, fork } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { Client } from "../../utils/client";
import { DR_LOAD, drLoadSuccess } from "./actions";
import { setError } from "../../redux-root/actions";

export function* drLoad() {
  try {
    yield put(drLoadSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchDRLoad() {
  yield takeEvery(DR_LOAD, drLoad);
}

export function* drSaga() {
  yield fork(watchDRLoad);
}
