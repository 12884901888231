export const LINK_SERVICE = 'link/link_service';
export const linkService = (data) => ({
  type: LINK_SERVICE,
  payload: data
});

export const LINK_SERVICE_SUCCESS = 'link/link_service_success';
export const linkServiceSuccess = () => ({
  type: LINK_SERVICE_SUCCESS
});

export const LINK_SERVICE_ERROR = 'link/link_service_error';
export const linkServiceError = () => ({
  type: LINK_SERVICE_ERROR
});

export const LINK_RESET = 'link/service_reset';
export const linkReset = () => ({
  type: LINK_RESET
});
