import {
  SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS,
  SUBSCRIPTIONS_DETAIL_LOAD,
} from "./actions";

const initialState = {
  contentDetail: null,
  loaded: false,
};

export function subscriptionsDetailReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        contentDetail: data,
        loaded: true,
      };

    case SUBSCRIPTIONS_DETAIL_LOAD:
      return {
        ...state,
        contentDetail: null,
        loaded: false,
      };

    default:
      return state;
  }
}
