export const ACCOUNTDETAIL_LOAD = "accountDetail/on_load";
export const accountDetailLoad = () => ({
  type: ACCOUNTDETAIL_LOAD,
});

export const ACCOUNTDETAIL_LOAD_SUCCESS = "accountDetail/on_load_success";
export const accountDetailLoadSuccess = (data, services) => ({
  type: ACCOUNTDETAIL_LOAD_SUCCESS,
  payload: data,
  services: services
});

export const PASSWORD_CHECK = "accountDetail/password_check";
export const passwordCheck = (data) => ({
  type: PASSWORD_CHECK,
  payload: data
});

export const PASSWORD_CHECK_SUCCESS = "accountDetail/password_check_success";
export const passwordCheckSuccess = () => ({
  type: PASSWORD_CHECK_SUCCESS
});

export const PASSWORD_CHECK_ERROR = "accountDetail/password_check_error";
export const passwordCheckError = () => ({
  type: PASSWORD_CHECK_ERROR
});

export const EMAIL_OTP = "accountDetail/email_otp";
export const emailOtp = (data) => ({
  type: EMAIL_OTP,
  payload: data
});

export const EMAIL_OTP_SUCCESS = "accountDetail/email_otp_success";
export const emailOtpSuccess = () => ({
  type: EMAIL_OTP_SUCCESS
});

export const EMAIL_CHANGE = "accountDetail/email_change";
export const emailChange = (data) => ({
  type: EMAIL_CHANGE,
  payload: data
});

export const EMAIL_CHANGE_SUCCESS = "accountDetail/email_change_success";
export const emailChangeSuccess = () => ({
  type: EMAIL_CHANGE_SUCCESS
});

export const EMAIL_CHANGE_ERROR = "accountDetail/email_change_error";
export const emailChangeError = () => ({
  type: EMAIL_CHANGE_ERROR
});

export const PASSWORD_CHANGE = "accountDetail/password_change";
export const passwordChange = (data) => ({
  type: PASSWORD_CHANGE,
  payload: data
});

export const PASSWORD_CHANGE_SUCCESS = "accountDetail/password_change_success";
export const passwordChangeSuccess = () => ({
  type: PASSWORD_CHANGE_SUCCESS
});

export const FORGOT_OTP = "accountDetail/forgot_otp";
export const forgotOtp = () => ({
  type: FORGOT_OTP
});

export const FORGOT_OTP_SUCCESS = "accountDetail/forgot_otp_success";
export const forgotOtpSuccess = () => ({
  type: FORGOT_OTP_SUCCESS
});

export const FORGOT_CHANGE = "accountDetail/forgot_change";
export const forgotChange = (data) => ({
  type: FORGOT_CHANGE,
  payload: data
});

export const FORGOT_CHANGE_SUCCESS = "accountDetail/forgot_change_success";
export const forgotChangeSuccess = () => ({
  type: FORGOT_CHANGE_SUCCESS
});

export const FORGOT_CHANGE_ERROR = "accountDetail/forgot_change_error";
export const forgotChangeError = () => ({
  type: FORGOT_CHANGE_ERROR
});

export const EMAIL_CHECK_OTP = "accountDetail/email_check_otp";
export const emailCheckOtp = (data) => ({
  type: EMAIL_CHECK_OTP,
  payload: data
});

export const EMAIL_CHECK_OTP_SUCCESS = "accountDetail/email_check_otp_success";
export const emailCheckOtpSuccess = () => ({
  type: EMAIL_CHECK_OTP_SUCCESS
});

export const EMAIL_VERIFY_OTP = "accountDetail/email_verify_otp";
export const emailVerifyOtp = (data) => ({
  type: EMAIL_VERIFY_OTP,
  payload: data
});

export const EMAIL_VERIFY_OTP_SUCCESS = "accountDetail/email_verify_otp_success";
export const emailVerifyOtpSuccess = () => ({
  type: EMAIL_VERIFY_OTP_SUCCESS
});

export const EMAIL_VERIFY_OTP_ERROR = "accountDetail/email_verify_otp_error";
export const emailVerifyOtpError = () => ({
  type: EMAIL_VERIFY_OTP_ERROR
});

export const MOBILE_SENT_OTP = "accountDetail/mobile_sent_otp";
export const mobileSentOtp = (data) => ({
  type: MOBILE_SENT_OTP,
  payload: data
});

export const MOBILE_SENT_OTP_SUCCESS = "accountDetail/mobile_sent_otp_success";
export const mobileSentOtpSuccess = () => ({
  type: MOBILE_SENT_OTP_SUCCESS
});

export const MOBILE_CHANGE_CHECK = "accountDetail/mobile_change_check";
export const mobileChangeCheck = (data) => ({
  type: MOBILE_CHANGE_CHECK,
  payload: data
});

export const MOBILE_CHANGE_CHECK_SUCCESS = "accountDetail/mobile_change_check_success";
export const mobileChangeCheckSuccess = () => ({
  type: MOBILE_CHANGE_CHECK_SUCCESS
});

export const MOBILE_CHANGE_CHECK_ERROR = "accountDetail/mobile_change_check_error";
export const mobileChangeCheckError = () => ({
  type: MOBILE_CHANGE_CHECK_ERROR
});

export const ACCOUNT_LINK_SERVICE = 'accountDetail/account_link_service';
export const accountLinkService = (data) => ({
  type: ACCOUNT_LINK_SERVICE,
  payload: data
});

export const ACCOUNT_LINK_SERVICE_SUCCESS = 'accountDetail/account_link_service_success';
export const accountLinkServiceSuccess = () => ({
  type: ACCOUNT_LINK_SERVICE_SUCCESS
});

export const ACCOUNT_LINK_SERVICE_ERROR = 'accountDetail/account_link_service_error';
export const accountLinkServiceError = () => ({
  type: ACCOUNT_LINK_SERVICE_ERROR
});

export const ACCOUNT_UNLINK_SERVICE = 'accountDetail/account_unlink_service';
export const accountUnlinkService = (data) => ({
  type: ACCOUNT_UNLINK_SERVICE,
  payload: data
});

export const ACCOUNT_UNLINK_SERVICE_SUCCESS = 'accountDetail/account_unlink_service_success';
export const accountUnlinkServiceSuccess = (data) => ({
  type: ACCOUNT_UNLINK_SERVICE_SUCCESS,
  payload: data
});
