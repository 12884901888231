import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { UAParser } from 'ua-parser-js';

import { Client } from '../../utils/client';
import { STREAM_LOAD, streamLoadSuccess, STREAM_CHECK_CONCURRENT, streamCheckConcurrentFull, STREAM_LOAD_CHANNEL, streamLoadChannelSuccess, LOAD_OTHER, loadOtherSuccess, CHANNEL_SEARCH, channelSearchSuccess, channelSearchError } from './actions';
import { setError } from '../../redux-root/actions';

const parser = new UAParser();
const ua = parser.getResult();
const isMac = ua.os.name === 'Mac OS' ? true : false;
const isChrome = ua.browser.name === 'Chrome' ? true : false;
const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
const hlsMode = isApple && !(isChrome && isMac) ? true : false;

export function* streamLoad(action) {
  const { payload } = action;

  try {
    const streamRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      "screenType": "TV",
      "streamingProtocol": hlsMode ? "Hls" : "Dash"
    };

    const streamData = yield call(Client.postData, `${payload}/play`, streamRequest);
    // console.log(streamData);

    yield put(streamLoadSuccess(streamData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* streamConcurrent(action) {
  const { payload } = action;

  try {
    const streamRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      mediaType: payload.type,
      mediaId: payload.id
    };

    yield call(Client.postData, '/me/streamingsessions', streamRequest);
  } catch (error) {
    if (error.status === 400) {
      yield put(streamCheckConcurrentFull());
    }
  }
}

export function* streamLiveLoad(action) {
  const { payload } = action;

  // console.log(JSON.stringify(ua));

  try {
    const streamRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      "screenType": "TV",
      "streamingProtocol": hlsMode ? "Hls" : "Dash"
    };

    const channelData = yield call(Client.getData, `/channels/${payload}`);
    let streamData;
    let meToken;

    if (channelData.data.isFree) {
      streamData = yield call(Client.postData, `/channels/free/${payload}/play`, streamRequest);
    } else {
      streamData = yield call(Client.postData, `/channels/${payload}/play`, streamRequest);
    }

    if (streamData.data.urls && streamData.data.urls[0] && streamData.data.urls[0].drmProvider && streamData.data.urls[0].drmProvider === 'MediaCorp') {
      meToken = yield call(Client.getData, '/channels/mediacorp/token');

      streamData.data.urls[0].drmToken = meToken;
    }

    yield put(streamLoadChannelSuccess(channelData.data, streamData.data));
  } catch (error) {
    console.log(error);

    if (error.status === 404) {
      yield put(setError('Video Not Available', `Oops! The content you're looking for could not be found. Please check your subscription and try again.`, error));
    } else {
      yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
    }
  }
}

export function* loadOther() {
  try {
    let historyChannelDataList = {
      channels: []
    };
    let favouriteData = {
      channel: [],
      movie: [],
      show: []
    };

    if (localStorage.getItem('_r')) {
      const nowDate = new Date();

      const [historyChannelData, favouriteChannelData] = yield all([
        call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`),
        call(Client.getData, '/me/playlists/handles/watchlater/channels')
      ]);

      historyChannelDataList = historyChannelData.data;
      favouriteData.channel = favouriteChannelData.data;
    }

    yield put(loadOtherSuccess(historyChannelDataList, favouriteData));
  } catch (error) {
    console.log(error);

    // yield put(setError('Player Title', 'Player Message description'));
  }
}

export function* channelSearch(action) {
  const { payload } = action;

  try {
    const channelData = yield call(Client.getData, `/channels/numbers/${payload}`);

    yield put(channelSearchSuccess(channelData.data));
  } catch (error) {
    // console.log(error);

    yield put(channelSearchError());
  }
}

function* watchStreamLoad() {
  yield takeEvery(STREAM_LOAD, streamLoad);
}

function* watchStreamConcurrent() {
  yield takeEvery(STREAM_CHECK_CONCURRENT, streamConcurrent);
}

function* watchLiveStreamLoad() {
  yield takeEvery(STREAM_LOAD_CHANNEL, streamLiveLoad);
}

function* watchLoadOther() {
  yield takeEvery(LOAD_OTHER, loadOther);
}

function* watchChannelSearch() {
  yield takeEvery(CHANNEL_SEARCH, channelSearch);
}

export function* playerSaga() {
  yield fork(watchStreamLoad);
  yield fork(watchStreamConcurrent);
  yield fork(watchLiveStreamLoad);
  yield fork(watchLoadOther);
  yield fork(watchChannelSearch);
}
