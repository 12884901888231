import hashSha256 from '../utils/sha256';
import {
  SET_APP_MODE,
  ON_LOAD_SUCCESS,
  TOGGLE_LOADING,
  SET_ERROR,
  SET_AUTHENTICATED,
  SET_ANIMATION,
  SET_STREAM_VOD,
  SET_PREVIOUS_PATH,
  CLEAR_STREAM_VOD,
  SET_MODAL,
  SET_CHANNEL_BACK_PATH,
  SET_DR_MODE,
  SET_NOW,
  SET_CONFIG
} from "./actions";
import {
  HOME_LOAD,
  LOGIN_CHECK,
  DETAILS_LOAD,
  ONDEMAND_LOAD,
  SEARCH_PAGE_LOAD,
  ABOUTUS_LOAD,
  ACCOUNTDETAIL_LOAD,
  PARENTALCONTROL_LOAD,
  LOGIN_CHECK_SUCCESS,
  TV_CODE_LOAD_SUCCESS,
  HOME_LOAD_SUCCESS,
  SUBSCRIPTIONS_LOAD,
  GUIDE_LOAD,
  DR_LOAD,
  GUIDE_LOAD_SUCCESS,
  SUBSCRIPTIONS_LOAD_SUCCESS,
  SUBSCRIPTIONS_DETAIL_LOAD,
  SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS,
  WATCHHISTORY_LOAD,
  WATCHHISTORY_LOAD_SUCCESS,
  FAVOURITE_LOAD,
  FAVOURITE_LOAD_SUCCESS,
  DETAILS_LOAD_SUCCESS,
  ONDEMAND_LOAD_SUCCESS,
  SEARCH_PAGE_LOAD_SUCCESS,
  ABOUTUS_LOAD_SUCCESS,
  ACCOUNTDETAIL_LOAD_SUCCESS,
  PARENTALCONTROL_LOAD_SUCCESS,
  STREAM_LOAD_CHANNEL_SUCCESS,
  GUIDE_REFRESH_SUCCESS,
  LOGIN_PROFILE_LOAD_SUCCESS,
  CHANGE_RATING_SUCCESS,
  DR_LOAD_SUCCESS,
} from "../redux-root/actions";

const initialState = {
  initialLoad: false,
  loading: true,
  error: null,
  authenticated: false,
  channelData: null,
  epgData: null,
  packageData: null,
  profileData: null,
  animation: null,
  streamVod: null,
  prevPath: null,
  modal: null,
  prevChannelPath: null,
  profileLoaded: false,
  config: null,
  skipStep: false,
  showTerms: false,
  hashUserId: null,
  drMode: false,
  drData: null,
  now: new Date(),
  tvMode: window.tizen ? true : false,
};

export function appReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case ON_LOAD_SUCCESS:
      return {
        ...state,
        initialLoad: true,
        channelData: data.channelData,
        epgData: data.epg,
        packageData: data.packages
      };

    case HOME_LOAD:
    case SUBSCRIPTIONS_LOAD:
    case SUBSCRIPTIONS_DETAIL_LOAD:
    case FAVOURITE_LOAD:
    case WATCHHISTORY_LOAD:
    case DETAILS_LOAD:
    case ONDEMAND_LOAD:
    case SEARCH_PAGE_LOAD:
    case ABOUTUS_LOAD:
    case ACCOUNTDETAIL_LOAD:
    case PARENTALCONTROL_LOAD:
    case GUIDE_LOAD:
    case LOGIN_CHECK:
    case DR_LOAD:
      return {
        ...state,
        loading: true
      };

    case TV_CODE_LOAD_SUCCESS:
    case HOME_LOAD_SUCCESS:
    case FAVOURITE_LOAD_SUCCESS:
    case WATCHHISTORY_LOAD_SUCCESS:
    case SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS:
    case DETAILS_LOAD_SUCCESS:
    case ONDEMAND_LOAD_SUCCESS:
    case SEARCH_PAGE_LOAD_SUCCESS:
    case ABOUTUS_LOAD_SUCCESS:
    case PARENTALCONTROL_LOAD_SUCCESS:
    case STREAM_LOAD_CHANNEL_SUCCESS:
    case GUIDE_LOAD_SUCCESS:
    case DR_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TOGGLE_LOADING:
      let newLoading = !state.loading;

      if (data === false) {
        newLoading = data;
      }

      return {
        ...state,
        loading: newLoading
      };

    case SET_ERROR:
      if (data.title) {
        return {
          ...state,
          loading: false,
          error: data,
        };
      }

      return {
        ...state,
        error: null,
      };

    case SET_AUTHENTICATED:
      let newState = {
        authenticated: data
      };

      if (data) {
        localStorage.setItem("_r", true);
      } else {
        localStorage.removeItem("_r");
        localStorage.removeItem("_skip");
        localStorage.removeItem("_lc");

        newState.profileData = null;
        newState.profileLoaded = false;
        newState.hashUserId = null;
      }

      return {
        ...state,
        ...newState
      };

    case LOGIN_CHECK_SUCCESS:
      let hashId = null;

      if (data.id) {
        hashId = hashSha256(state.config.gtmSalt + data.id);
      }

      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...data
        },
        hashUserId: hashId
      };

    case LOGIN_PROFILE_LOAD_SUCCESS:
      let newSkipStep = false;
      let newShowTerms = false;

      if (localStorage.getItem("_skip")) {
        newSkipStep = true;
      } else if (data.packages && data.packages.length > 0) {
        newSkipStep = true;
      }

      if (!data.consent.acceptedTermsOfService) {
        newShowTerms = true;
      }

      return {
        ...state,
        profileLoaded: true,
        profileData: data,
        skipStep: newSkipStep,
        showTerms: newShowTerms
      };

    case SET_ANIMATION:
      return {
        ...state,
        animation: data,
      };

    case SET_STREAM_VOD:
      return {
        ...state,
        streamVod: {
          data: data,
          url: action.url,
        },
      };

    case CLEAR_STREAM_VOD:
      return {
        ...state,
        streamVod: null,
      };

    case SET_PREVIOUS_PATH:
      return {
        ...state,
        prevPath: data,
      };

    case SET_MODAL:
      if (data.title) {
        return {
          ...state,
          modal: data
        };
      };

      return {
        ...state,
        modal: null
      };

    case SET_CHANNEL_BACK_PATH:
      return {
        ...state,
        prevChannelPath: data
      };

    case GUIDE_REFRESH_SUCCESS:
      return {
        ...state,
        epgData: data
      };

    case CHANGE_RATING_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          parental: data
        }
      };

    case ACCOUNTDETAIL_LOAD_SUCCESS:
    case SUBSCRIPTIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: {
          ...state.profileData,
          ...data
        }
      }

    case SET_DR_MODE:
      let newDrState = {
        drMode: !state.drMode
      };

      if (data) {
        newDrState.drData = data;
      }

      return {
        ...state,
        ...newDrState,
        loading: false
      };

    case SET_NOW:
      return {
        ...state,
        now: data
      };

    case SET_CONFIG:
      return {
        ...state,
        config: data
      };

    default:
      return state;
  }
}
