export const TERMS_SET_CONSENT = 'terms/set_consent';
export const termsSetConsent = (data) => ({
  type: TERMS_SET_CONSENT,
  payload: data
});

export const TERMS_SET_CONSENT_SUCCESS = 'terms/set_consent_success';
export const termsSetConsentSuccess = () => ({
  type: TERMS_SET_CONSENT_SUCCESS
});
