import {
  LINK_SERVICE,
  LINK_SERVICE_SUCCESS,
  LINK_SERVICE_ERROR,
  LINK_RESET
} from './actions';

const initialState = {
  serviceError: false,
  serviceSuccess: false
};

export function linkReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case LINK_SERVICE:
      return {
        ...state,
        serviceError: false
      };

    case LINK_SERVICE_SUCCESS:
      return {
        ...state,
        serviceSuccess: true
      };

    case LINK_SERVICE_ERROR:
      return {
        ...state,
        serviceError: true
      };

    case LINK_RESET:
      return {
        ...state,
        serviceSuccess: false
      };

    default:
      return state;
  }
}
