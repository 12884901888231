import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import TagManager from 'react-gtm-module';
import { UAParser } from 'ua-parser-js';
import { Amplify } from "aws-amplify";
import { DateTime } from "luxon";

import { Client } from '../utils/client';
import { MeClient } from '../utils/meClient';
import { ON_LOAD, onLoadSuccess, setError, setDrMode, setConfig } from './actions';
import epgTimeJson from '../config/epg-time.json';

let lastAction = {};

export function* initSession() {
  let drFile;

  try {
    drFile = yield call(Client.get, process.env.REACT_APP_DR_URL, null, {
      headers: {
        'x-api-key': process.env.REACT_APP_KEY
      }
    });
  } catch (error) {
    drFile = null;
  }

  let config = yield call(Client.get, process.env.REACT_APP_BOOTSTRAP_URL, null, {
    headers: {
      'x-api-key': process.env.REACT_APP_KEY
    }
  });

  Client.setBaseURL(config.api);
  MeClient.setBaseURL(config.mediacorp.api);

  yield put(setConfig(config));

  if (!drFile) {
    Amplify.configure({
      aws_cognito_region: config.cognito.region,
      aws_user_pools_id: config.cognito.userPoolId,
      aws_user_pools_web_client_id: config.cognito.appClientId,
    });

    const tagManagerArgs = {
      gtmId: config.gtm
    };

    TagManager.initialize(tagManagerArgs);

    try {
      const ipData = yield call(Client.getData, '/viewer/ip');

      Client.setKey(process.env.REACT_APP_KEY);

      if (!localStorage.getItem('_d')) {
        const deviceUuid = uuidv4();
        localStorage.setItem('_d', deviceUuid);

        let deviceRequest = {
          requestId: uuidv4(),
          messagingProvider: null,
          messagingEndpoint: null
        };

        const parser = new UAParser();
        const ua = parser.getResult();

        if (window.tizen) {
          let tvConfig = {};
          tvConfig.model = window.webapis.productinfo.getModel();
          tvConfig.deviceId = localStorage.getItem('_d');
          tvConfig.platformVersion = navigator.userAgent.split(';')[2].split(' ')[1].slice(0, -1);
          tvConfig.ipAddress = ipData.ip;
          tvConfig.name = window.webapis.network.getTVName();
          tvConfig.deviceType = 'TV';
          tvConfig.screenType = 'TV';
          tvConfig.platformType = 'Tizen';
          tvConfig.clientApp = "Cast Tizen TV";
          tvConfig.clientAppVersion = "1.0.0";

          deviceRequest = Object.assign(deviceRequest, tvConfig);
        } else {
          let newPlatformType = ua.os.name;

          if (newPlatformType === 'Mac OS') {
            newPlatformType = 'macOS';
          }

          let webConfig = {};
          webConfig.model = "NA";
          webConfig.deviceId = localStorage.getItem('_d');
          webConfig.platformVersion = ua.os.version;
          webConfig.ipAddress = ipData.ip;
          webConfig.name = ua.browser.name;
          webConfig.deviceType = 'Computer';
          webConfig.screenType = 'Responsive Web';
          webConfig.platformType = newPlatformType;
          webConfig.clientApp = "Cast Web";
          webConfig.clientAppVersion = "1.0.0";

          deviceRequest = Object.assign(deviceRequest, webConfig);
        }

        yield call(Client.postData, '/devices', deviceRequest);
      }

      let [channelData, channelOnDemandData, freeChannelData, packageData] = yield all([
        call(Client.getData, '/channels/?offset=0&limit=200'),
        call(Client.getData, '/channelondemands/?offset=0&limit=200'),
        call(Client.getData, '/channels/free?offset=0&limit=200'),
        call(Client.getData, '/packages/partnerapps/?offset=0&limit=200')
      ]);

      const finalChannelData = {
        channel: channelData.data,
        channelOnDemand: channelOnDemandData.data,
        freeChannel: freeChannelData.data
      };

      const now = DateTime.now().setZone('Asia/Singapore');
      let startDate = now.minus({ hours: 13 }).set({ minute: 0, second: 0, millisecond: 0 });
      let endDate = now.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

      let epgList = [];
      let epgData = {
        data: []
      };

      const hourDiff = Math.ceil(endDate.diff(startDate, "hours").toObject().hours);
      const numberOfFiles = Math.ceil(hourDiff / 6);

      startDate = startDate.set({ hour: epgTimeJson.epg.find(e => startDate.hour >= e) });

      for (let i = 0; i < numberOfFiles; i++) {
        const newEndDate = startDate.plus({ hours: 5 }).set({ minute: 59, second: 59, millisecond: 999 });

        epgList.push(call(Client.getData, `/channels/epg/?offset=0&limit=10000&startdate=${startDate.toUTC().toISO()}&enddate=${newEndDate.toUTC().toISO()}`));
        
        startDate = startDate.plus({ hours: 6 });
      }

      const finalEpgList = yield all(epgList);
      finalEpgList.forEach(e => {
        epgData.data = [...epgData.data, ...e.data];
      });

      const finalEpgData = epgData.data.filter(p => {
        const pStartDate = new Date(p.startDate);
        pStartDate.setHours(pStartDate.getHours() + 8);
        pStartDate.setSeconds(pStartDate.getSeconds() + p.duration);

        if (pStartDate > new Date()) {
          return p;
        }

        return false;
      });

      localStorage.setItem('_et', new Date().toString());

      if (localStorage.getItem('_me')) {
        let meTokenData = JSON.parse(localStorage.getItem('_me'));
        const meData = yield call(MeClient.getData, `/mobile_app/init?network=singtel&device_id=${localStorage.getItem('_d')}&meid=${meTokenData.meid}&token=${config.mediacorp.token}`);

        localStorage.setItem('_me', JSON.stringify(meData));
      } else {
        const meData = yield call(MeClient.getData, `/mobile_app/init?network=singtel&device_id=${localStorage.getItem('_d')}&token=${config.mediacorp.token}`);

        localStorage.setItem('_me', JSON.stringify(meData));
      }

      yield put(onLoadSuccess(finalChannelData, finalEpgData, packageData.data));
    } catch (error) {
      console.log(error);

      // yield put(setError('App Title', 'App Message description'));
    }
  } else {
    if (localStorage.getItem('_me')) {
      let meTokenData = JSON.parse(localStorage.getItem('_me'));
      const meData = yield call(MeClient.getData, `/mobile_app/init?network=singtel&device_id=${localStorage.getItem('_d')}&meid=${meTokenData.meid}&token=${config.mediacorp.token}`);

      localStorage.setItem('_me', JSON.stringify(meData));
    } else {
      const meData = yield call(MeClient.getData, `/mobile_app/init?network=singtel&device_id=${localStorage.getItem('_d')}&token=${config.mediacorp.token}`);

      localStorage.setItem('_me', JSON.stringify(meData));
    }

    yield put(setDrMode(drFile.data));
  }
}

export function* saveLog(action) {
  if (lastAction.type !== action.type) {
    if (action.type === 'error/retry_action') {
      yield put(setError(null));
      yield put(lastAction);
    }
  }

  if (!action.type.includes('error')) {
    lastAction = action;
  }
}

function* watchOnLoad() {
  yield takeEvery(ON_LOAD, initSession);
}

function* watchAndLog() {
  yield takeEvery('*', saveLog);
}

export function* appSaga() {
  yield fork(watchOnLoad);
  yield fork(watchAndLog);
}
