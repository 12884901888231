export const SET_APP_MODE = 'app/set_mode';
export const setAppMode = (data) => ({
  type: SET_APP_MODE,
  payload: data
});

export const ON_LOAD = 'app/on_load';
export const onLoad = () => ({
  type: ON_LOAD
});

export const ON_LOAD_SUCCESS = 'app/on_load_success';
export const onLoadSuccess = (channelData, epg, packages) => ({
  type: ON_LOAD_SUCCESS,
  payload: {
    channelData: channelData,
    epg: epg,
    packages: packages
  }
});

export const TOGGLE_LOADING = 'app/toggle_loading';
export const toggleLoading = (bool) => ({
  type: TOGGLE_LOADING,
  payload: bool
});

export const SET_ERROR = 'app/error';
export const setError = (title, message, obj) => ({
  type: SET_ERROR,
  payload: {
    title: title,
    message: message,
    obj: obj
  }
});

export const TOGGLE_ERROR = 'app/error';
export const toggleError = (data) => ({
  type: TOGGLE_ERROR,
  payload: data
});


export const SET_AUTHENTICATED = 'app/set_authenticated';
export const setAuthenticated = (data) => ({
  type: SET_AUTHENTICATED,
  payload: data
});

export const SET_ANIMATION = 'app/set_animation';
export const setAnimation = (data) => ({
  type: SET_ANIMATION,
  payload: data
});

export const SET_STREAM_VOD = 'app/set_stream_vod';
export const setStreamVod = (data, url) => ({
  type: SET_STREAM_VOD,
  payload: data,
  url: url
});

export const CLEAR_STREAM_VOD = 'app/clear_stream_vod';
export const clearStreamVod = () => ({
  type: CLEAR_STREAM_VOD
});

export const SET_PREVIOUS_PATH = 'app/set_previous_path';
export const setPreviousPath = (data) => ({
  type: SET_PREVIOUS_PATH,
  payload: data
});

export const SET_MODAL = 'app/set_modal';
export const setModal = (title, description, buttonText, onClick, onBack, checkboxObj, cancelButtonText, onCancel) => ({
  type: SET_MODAL,
  payload: {
    title: title,
    description: description,
    buttonText: buttonText,
    onClick: onClick,
    onBack: onBack,
    checkboxObj: checkboxObj,
    cancelButtonText: cancelButtonText,
    onCancel: onCancel
  }
});

export const SET_CHANNEL_BACK_PATH = 'app/set_channel_back_path';
export const setChannelBackPath = (data) => ({
  type: SET_CHANNEL_BACK_PATH,
  payload: data
});

export const SET_DR_MODE = 'app/set_dr_mode';
export const setDrMode = (data) => ({
  type: SET_DR_MODE,
  payload: data
});

export const SET_NOW = 'app/now_time';
export const setNow = (data) => ({
  type: SET_NOW,
  payload: data
});

export const SET_CONFIG = 'app/set_config';
export const setConfig = (data) => ({
  type: SET_CONFIG,
  payload: data
});
