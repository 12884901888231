export const TOGGLE_MENU = 'menu/toggle';
export const toggleMenu = () => ({
  type: TOGGLE_MENU
});

export const CLEAR_HISTORY = 'menu/clear_history';
export const clearHistory = data => ({
  type: CLEAR_HISTORY,
  payload: data
});

export const CLEAR_HISTORY_SUCCESS = 'menu/clear_history_success';
export const clearHistorySuccess = data => ({
  type: CLEAR_HISTORY_SUCCESS,
  payload: data
});
