export const SUBSCRIPTIONS_DETAIL_LOAD = "subscriptionsDetail/on_load";
export const subscriptionsDetailLoad = (id) => ({
  type: SUBSCRIPTIONS_DETAIL_LOAD,
  id: id,
});

export const SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS =
  "subscriptionsDetail/on_load_success";
export const subscriptionsDetailLoadSuccess = (data) => ({
  type: SUBSCRIPTIONS_DETAIL_LOAD_SUCCESS,
  payload: data,
});
