export default function gtmHelper(obj) {
  let userId = obj.userId;

  if (!userId) {
    userId = 'NA';
  }

  window.dataLayer.push({
    event: undefined,
    props: undefined
  });

  const finalGtmObj = {
    event: obj.category,
    props: {
      ...obj,
      name: `${obj.category}_${obj.action}`,
      platform: window.tizen ? 'tv' : 'web',
      userId: userId
    }
  };

  window.dataLayer.push(finalGtmObj);
};
