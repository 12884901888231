export const DETAILS_LOAD = 'details/load';
export const detailsLoad = (isMovie, id) => ({
  type: DETAILS_LOAD,
  isMovie: isMovie,
  id: id
});

export const DETAILS_LOAD_SUCCESS = 'details/load_success';
export const detailsLoadSuccess = (data, more, favourite, history) => ({
  type: DETAILS_LOAD_SUCCESS,
  payload: data,
  more: more,
  favourite: favourite,
  history: history
});
