import { takeEvery, put, call, fork } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { Client } from "../../utils/client";
import { ABOUTUS_LOAD, aboutUsLoadSuccess } from "./actions";
import { setError } from "../../redux-root/actions";

export function* aboutUsLoad() {
  try {
    yield put(aboutUsLoadSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchAboutUsLoad() {
  yield takeEvery(ABOUTUS_LOAD, aboutUsLoad);
}

export function* aboutUsSaga() {
  yield fork(watchAboutUsLoad);
}
