import {
  TERMS_SET_CONSENT_SUCCESS
} from './actions';

const initialState = {
  termsSuccess: false
};

export function termsReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case TERMS_SET_CONSENT_SUCCESS:
      return {
        ...state,
        termsSuccess: true
      };

    default:
      return state;
  }
}
