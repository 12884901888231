import {
  PARENTAL_CHECK_PIN,
  PARENTAL_CHECK_PIN_SUCCESS,
  PARENTAL_SEND_OTP,
  PARENTAL_SEND_OTP_SUCCESS,
  PARENTAL_SET_PIN_SUCCESS,
  PARENTAL_SET_PIN_ERROR,
  CHANGE_CHECK_PIN,
  CHANGE_CHECK_PIN_SUCCESS,
  PARENTALCONTROL_LOAD_SUCCESS,
  CHANGE_CHECK_PIN_RESET
} from './actions';

const initialState = {
  checkLoaded: false,
  pinCheck: false,
  otpSent: false,
  setPinSuccess: false,
  setPinError: false,
  changeCheckLoaded: false,
  changePinCheck: false
};

export function parentalReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case PARENTAL_CHECK_PIN:
      return {
        ...state,
        checkLoaded: false,
        pinCheck: false
      };

    case PARENTAL_CHECK_PIN_SUCCESS:
      return {
        ...state,
        checkLoaded: true,
        pinCheck: data
      };

    case PARENTAL_SEND_OTP:
      return {
        ...state,
        setPinError: false
      };

    case PARENTAL_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true
      };

    case PARENTAL_SET_PIN_SUCCESS:
      return {
        ...state,
        setPinSuccess: true
      };

    case PARENTAL_SET_PIN_ERROR:
      return {
        ...state,
        setPinError: true
      };

    case CHANGE_CHECK_PIN:
    case PARENTALCONTROL_LOAD_SUCCESS:
    case CHANGE_CHECK_PIN_RESET:
      return {
        ...state,
        changeCheckLoaded: false,
        changePinCheck: false
      };

    case CHANGE_CHECK_PIN_SUCCESS:
      return {
        ...state,
        changeCheckLoaded: true,
        changePinCheck: data
      };

    default:
      return state;
  }
}
