export const SETUP_SEND_OTP = 'setup/email_send_otp';
export const setupSendOtp = (data) => ({
  type: SETUP_SEND_OTP,
  payload: data
});

export const SETUP_SEND_OTP_SUCCESS = 'setup/email_send_otp_success';
export const setupSendOtpSuccess = () => ({
  type: SETUP_SEND_OTP_SUCCESS
});

export const SETUP_SET_DETAILS = 'setup/set_details';
export const setupSetDetails = (data) => ({
  type: SETUP_SET_DETAILS,
  payload: data
});

export const SETUP_SET_DETAILS_SUCCESS = 'setup/set_details_success';
export const setupSetDetailsSuccess = () => ({
  type: SETUP_SET_DETAILS_SUCCESS
});

export const SETUP_SET_DETAILS_ERROR = 'setup/set_details_error';
export const setupSetDetailsError = (data) => ({
  type: SETUP_SET_DETAILS_ERROR,
  payload: data
});
