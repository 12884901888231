import {
  DETAILS_LOAD,
  DETAILS_LOAD_SUCCESS,
} from './actions';

const initialState = {
  contentDetails: null,
  moreDetails: null
};

export function detailsReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case DETAILS_LOAD:
      return {
        ...state,
        contentDetails: null,
        moreDetails: null
      };

    case DETAILS_LOAD_SUCCESS:
      return {
        ...state,
        contentDetails: data,
        moreDetails: action.more
      };

    default:
      return state;
  }
}
