import {
  WATCHHISTORY_LOAD,
  WATCHHISTORY_LOAD_SUCCESS,
  HISTORY_ACTION_SUCCESS,
  HISTORY_DELETE_ALL
} from "./actions";
import {
  DETAILS_LOAD_SUCCESS,
  LOAD_OTHER_SUCCESS
} from "../../redux-root/actions";

const initialState = {
  channelData: null,
  movieData: null,
  showData: null,
  loaded: false
};

export function watchHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case WATCHHISTORY_LOAD:
      return {
        ...state,
        loaded: false,
        refreshedLoad: false
      };

    case WATCHHISTORY_LOAD_SUCCESS:
      return {
        ...state,
        channelData: action.channel,
        movieData: action.movie,
        showData: action.show,
        loaded: true,
        refreshedLoad: false
      };

    case HISTORY_DELETE_ALL:
      return {
        ...state,
        refreshedLoad: true,
      }

    case DETAILS_LOAD_SUCCESS:
    case HISTORY_ACTION_SUCCESS:
      if (action.history.movie) {
        return {
          ...state,
          movieData: action.history.movie,
          refreshedLoad: false
        };
      } else if (action.history.channel) {
        return {
          ...state,
          channelData: action.history.channel,
          refreshedLoad: false
        }
      }

      return {
        ...state,
        showData: action.history.show,
        refreshedLoad: false
      };

    case LOAD_OTHER_SUCCESS:
      return {
        ...state,
        channelData: action.channel
      }

    default:
      return state;
  }
}
