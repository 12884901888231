import { DR_LOAD, DR_LOAD_SUCCESS } from "./actions";

const initialState = {
  loaded: false
};

export function drReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case DR_LOAD:
      return {
        ...state,
      };

    case DR_LOAD_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
