export const GUIDE_LOAD = 'guide/on_load';
export const guideLoad = () => ({
  type: GUIDE_LOAD
});

export const GUIDE_LOAD_SUCCESS = 'guide/on_load_success';
export const guideLoadSuccess = (data, favourite) => ({
  type: GUIDE_LOAD_SUCCESS,
  payload: data,
  favourite: favourite
});

export const GUIDE_FILTER_DATE = 'guide/filter_date';
export const guideFilterDate = (date) => ({
  type: GUIDE_FILTER_DATE,
  payload: date
});

export const GUIDE_FILTER_DATE_SUCCESS = 'guide/filter_date_success';
export const guideFilterDateSuccess = (data) => ({
  type: GUIDE_FILTER_DATE_SUCCESS,
  payload: data
});

export const GUIDE_GET_PACKAGE = 'guide/get_package';
export const guideGetPackage = (date) => ({
  type: GUIDE_GET_PACKAGE,
  payload: date
});

export const GUIDE_GET_PACKAGE_SUCCESS = 'guide/get_package_success';
export const guideGetPackageSuccess = (data) => ({
  type: GUIDE_GET_PACKAGE_SUCCESS,
  payload: data
});

export const GUIDE_CLEAR_PACKAGE = 'guide/clear_package';
export const guideClearPackage = () => ({
  type: GUIDE_CLEAR_PACKAGE
});

export const GUIDE_REFRESH = 'guide/refresh';
export const guideRefresh = () => ({
  type: GUIDE_REFRESH
});

export const GUIDE_REFRESH_SUCCESS = 'guide/refresh_success';
export const guideRefreshSuccess = (data) => ({
  type: GUIDE_REFRESH_SUCCESS,
  payload: data
});
