import { takeEvery, put, call } from 'redux-saga/effects';

import { Client } from '../../utils/client';
import { CLEAR_HISTORY, clearHistorySuccess } from './actions';
import { toggleLoading, toggleError } from '../../redux-root/actions';

export function* clearHistory(action) {
  try {
    let requestData = action.payload;

    yield put(toggleLoading());

    requestData = Object.assign({}, requestData);

    if (!requestData.deviceId) {
      requestData.deviceId = 'W5PZ7RLF4CD4B'; // SAMPLE
    }

    yield call(Client.postData, '/User/ClearHistory', requestData);

    yield put(clearHistorySuccess());
  } catch (error) {
    console.log(error);

    yield put(toggleError('Please try again.'));
  }
}

function* watchClearHistory() {
  yield takeEvery(CLEAR_HISTORY, clearHistory);
}

export function* menuSaga() {
  yield watchClearHistory();
}
