import axios from 'axios';

// Axios config
const config = {
  baseURL: '',
  timeout: 60000,

  // Only throw if HTTP error > 4XX
  validateStatus: function (status) {
    return status < 500;
  }
}

// Create the axios singleton
var instance = axios.create(config);

/**
 * HTTP Client
 *
 * HTTP client wrapper for normal HTTP requests
 */
export class MeClient {
  /**
   * Set base URL as the default for making requests
   *
   * @param {string} url
   */
  static async setBaseURL(url) {
    instance.defaults.baseURL = url;
  }

  static async setToken(tokenType, token) {
    instance.defaults.headers.common["Authorization"] = `${tokenType} ${token}`;
  }

  /**
   * Get data from Mediacorp UID backend
   *
   * @param {object} data Query string
   */
  static async getData(url) {
    try {
      if (!instance.defaults.baseURL)
        throw Error("BaseURL not set!")

      const res = await instance.get(url);

      if (res.status !== 200)
        throw (res);

      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  /**
   * Post data to Mediacorp UID backend
   *
   * @param {object} data Query string
   */
  static async postData(url, data) {
    try {
      if (!instance.defaults.baseURL)
        throw Error("BaseURL not set!")

      const res = await instance.post(url, { ...data });

      if (res.status !== 200)
        throw (res);

      return res.data;
    } catch (e) {
      throw (e)
    }
  }
}