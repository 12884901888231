import {
  TOGGLE_MENU,
  CLEAR_HISTORY,
  CLEAR_HISTORY_SUCCESS
} from './actions';

const initialState = {
  menuCollapsed: true,
  checkingHistory: false
}

export function menuReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        menuCollapsed: !state.menuCollapsed
      };

    case CLEAR_HISTORY:
      return {
        ...state,
        checkingHistory: true
      }

    case CLEAR_HISTORY_SUCCESS:
      return {
        ...state,
        checkingHistory: false
      }

    default:
      return state;
  }
}
