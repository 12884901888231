import { takeEvery, put, call, fork, all } from "redux-saga/effects";
// import { v4 as uuidv4 } from 'uuid';

import { Client } from "../../utils/client";
import {
  ONDEMAND_LOAD,
  ondemandLoadSuccess,
  ONDEMAND_FILTER,
  ondemandFilterSuccess,
} from "./actions";
import { setError } from "../../redux-root/actions";

export function* ondemandLoad() {
  try {
    let favouriteData = {
      channel: [],
      movie: [],
      show: []
    };

    if (localStorage.getItem('_r')) {
      // const favouriteChannelData = yield call(
      //   Client.getData,
      //   "/me/playlists/handles/watchlater/channels"
      // );
      // const favouriteMovieData = yield call(
      //   Client.getData,
      //   "/me/playlists/handles/watchlater/movies"
      // );
      // const favouriteShowData = yield call(
      //   Client.getData,
      //   "/me/playlists/handles/watchlater/shows"
      // );

      // favouriteData.channel = favouriteChannelData.data;
      // favouriteData.movie = favouriteMovieData.data;
      // favouriteData.show = favouriteShowData.data;
    }

    const [vodData, genreData, languageData, showData, movieData] = yield all([
      call(Client.getData, "/pages/handles/vod-home-v4"),
      call(Client.getData, "/configurations/genres?ispopular=true"),
      call(Client.getData, "/configurations/languages?ispopular=true"),
      call(Client.getData, "/shows?offset=0&limit=200"),
      call(Client.getData, "/movies?offset=0&limit=200")
    ]);

    const pageData = {
      genre: genreData,
      language: languageData,
      show: showData,
      movieData: movieData,
    };

    yield put(ondemandLoadSuccess(vodData.data, pageData, favouriteData));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchOnDemandLoad() {
  yield takeEvery(ONDEMAND_LOAD, ondemandLoad);
}

export function* ondemandSaga() {
  yield fork(watchOnDemandLoad);
  yield fork(watchOnDemandFilter);
}

export function* ondemandFilter(action) {
  const { payload } = action;

  let filterArr = [];
  let url = "";

  let languageString = "";
  if (payload.filterList.language.length !== 0) {
    let languageArr = [];
    payload.filterList.language.map((lg) =>
      languageArr.push(lg.name)
    );
    languageString = languageArr.join(",");
    languageString = "&language".concat("=", languageString);
  }
  filterArr.push(languageString);

  let genreString = "";
  if (payload.filterList.genre.length !== 0) {
    let genreArr = [];
    payload.filterList.genre.map((gr) =>
      genreArr.push(gr.name)
    );
    genreString = genreArr.join(",");
    genreString = "&genre".concat("=", encodeURIComponent(genreString));
  }
  filterArr.push(genreString);

  let yearString = "";
  if (payload.filterList.time.length !== 0) {
    let yearArr = [];
    payload.filterList.time.map((tm) =>
      yearArr.push(tm.name)
    );
    yearString = yearArr.join(",");
    yearString = "&releaseyear".concat("=", yearString);
  }
  filterArr.push(yearString);

  if (payload.filterList.subscribeOnly) {
    if (payload.type === "Shows") {
      url = "/me/channelondemands/shows?offset=0&limit=200".concat(
        "",
        filterArr.join("")
      );
    } else {
      url = "/me/channelondemands/movies?offset=0&limit=200".concat(
        "",
        filterArr.join("")
      );
    }
  } else {
    if (payload.type === "Shows") {
      url = "/shows/?offset=0&limit=50".concat("", filterArr.join(""));
    } else {
      url = "/movies/?offset=0&limit=50".concat("", filterArr.join(""));
    }
  }

  try {
    const data = yield call(Client.getData, url);

    yield put(ondemandFilterSuccess(data));
  } catch (error) {
    yield put(ondemandFilterSuccess({ status: 200, data: [] }));

    // yield put(setError("On Demand Title", "On Demand Message description"));
  }
}

function* watchOnDemandFilter() {
  yield takeEvery(ONDEMAND_FILTER, ondemandFilter);
}
