import React from 'react';

import styles from './index.module.css';

const typeList = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'l16', 'l20', 'l24', 'lb24', 'l28', 'lb28', 'l32', 'lb32', 'lb40'];

function Text(props) {
  const { type, className, style, children } = props;

  let textClass = 'l20';

  if (typeList.includes(type) && type !== 'l20') {
    textClass = type;
  }

  return (
    <div className={`${styles[textClass]} ${styles['default']} ${className ? className : ""}`} style={style}>
      {children}
    </div>
  );
}

export default Text;