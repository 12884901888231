import { SUBSCRIPTIONS_LOAD, SUBSCRIPTIONS_LOAD_SUCCESS } from "./actions";

const initialState = {
  loaded: false
};

export function subscriptionsReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_LOAD:
      return {
        ...state,
        loaded: false
      };

    case SUBSCRIPTIONS_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        subscriptionData: action.data,
        partnerApps: action.partnerApps
      };

    default:
      return state;
  }
}
