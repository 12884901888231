export const TV_CODE_LOAD = 'login/tv_code_load';
export const tvCodeLoad = () => ({
  type: TV_CODE_LOAD
});

export const TV_CODE_LOAD_SUCCESS = 'login/tv_code_load_success';
export const tvCodeLoadSuccess = (data) => ({
  type: TV_CODE_LOAD_SUCCESS,
  payload: data
});

export const TV_CHECK_CODE = 'login/tv_check_code';
export const tvCheckCode = (data) => ({
  type: TV_CHECK_CODE,
  payload: data
});

export const TV_CHECK_CODE_SUCCESS = 'login/tv_check_code_success';
export const tvCheckCodeSuccess = (data) => ({
  type: TV_CHECK_CODE_SUCCESS,
  payload: data
});

export const LOGIN_CHECK = 'login/pre_check';
export const loginCheck = (data, auto) => ({
  type: LOGIN_CHECK,
  payload: data,
  auto: auto
});

export const LOGIN_CHECK_SUCCESS = 'login/pre_check_success';
export const loginCheckSuccess = (data) => ({
  type: LOGIN_CHECK_SUCCESS,
  payload: data
});

export const REFRESH_TOKEN = 'login/refresh_token';
export const refreshToken = (data) => ({
  type: REFRESH_TOKEN,
  payload: data
});

export const LOGIN_PROFILE_LOAD = 'login/profile_load';
export const loginProfileLoad = (data) => ({
  type: LOGIN_PROFILE_LOAD,
  freeChannel: data
});

export const LOGIN_PROFILE_LOAD_SUCCESS = 'login/profile_load_success';
export const loginProfileLoadSuccess = (data) => ({
  type: LOGIN_PROFILE_LOAD_SUCCESS,
  payload: data
});

export const LOGIN_CREATE_CUSTOMER = 'login/create_customer';
export const loginCreateCustomer = (data) => ({
  type: LOGIN_CREATE_CUSTOMER,
  payload: data
});

export const LOGIN_CREATE_CUSTOMER_SUCCESS = 'login/create_customer_success';
export const loginCreateCustomerSuccess = (data) => ({
  type: LOGIN_CREATE_CUSTOMER_SUCCESS,
  payload: data
});

export const FORGOT_SEND_OTP = "login/forgot_send_otp";
export const forgotSendOtp = (data) => ({
  type: FORGOT_SEND_OTP,
  payload: data
});

export const FORGOT_SEND_OTP_SUCCESS = "login/forgot_send_otp_success";
export const forgotSendOtpSuccess = () => ({
  type: FORGOT_SEND_OTP_SUCCESS
});

export const FORGOT_SEND_OTP_ERROR = "login/forgot_send_otp_error";
export const forgotSendOtpError = () => ({
  type: FORGOT_SEND_OTP_ERROR
});

export const RESET_PASS = "login/reset_pass";
export const resetPass = (data) => ({
  type: RESET_PASS,
  payload: data
});

export const RESET_PASS_SUCCESS = "login/reset_pass_success";
export const resetPassSuccess = () => ({
  type: RESET_PASS_SUCCESS
});

export const RESET_PASS_ERROR = "login/reset_pass_error";
export const resetPassError = () => ({
  type: RESET_PASS_ERROR
});

export const RESET_ALL = "login/reset_all_props";
export const resetAll = () => ({
  type: RESET_ALL
});
