import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { TERMS_SET_CONSENT, termsSetConsentSuccess } from './actions';
import { setError } from '../../redux-root/actions';

export function* setConsent(action) {
  const { payload } = action;

  try {
    const termsRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/consent', termsRequest);

    yield put(termsSetConsentSuccess());
  } catch (error) {
    // console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchSetConsent() {
  yield takeEvery(TERMS_SET_CONSENT, setConsent);
}

export function* termsSaga() {
  yield fork(watchSetConsent);
}
