import {
  FAVOURITE_ADD,
  FAVOURITE_REMOVE,
  FAVOURITE_LOAD,
  FAVOURITE_LOAD_SUCCESS,
  FAVOURITE_ACTION_SUCCESS,
  FAVOURITE_CHANNEL_LOAD_SUCCESS
} from "./actions";
import {
  HOME_LOAD_SUCCESS,
  ONDEMAND_LOAD_SUCCESS,
  DETAILS_LOAD_SUCCESS,
  GUIDE_LOAD_SUCCESS,
  LOAD_OTHER_SUCCESS
} from '../../redux-root/actions';

const initialState = {
  favouriteData: null,
  actionResult: null,
  favouriteLoaded: false
};

export function favouriteReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case FAVOURITE_LOAD:
      return {
        ...state,
        favouriteLoaded: false,
        favouriteData: null
      };

    case FAVOURITE_LOAD_SUCCESS:
      return {
        ...state,
        favouriteData: data,
        favouriteLoaded: true
      };

    case FAVOURITE_ADD:
    case FAVOURITE_REMOVE:
      return {
        ...state,
        actionResult: null
      };

    case FAVOURITE_ACTION_SUCCESS:
      return {
        ...state,
        actionResult: {
          id: data,
          status: action.status,
          addAction: action.addAction
        }
      };

    case HOME_LOAD_SUCCESS:
    case ONDEMAND_LOAD_SUCCESS:
    case DETAILS_LOAD_SUCCESS:
    case GUIDE_LOAD_SUCCESS:
    case LOAD_OTHER_SUCCESS:
      return {
        ...state,
        favouriteData: action.favourite
      };

    case FAVOURITE_CHANNEL_LOAD_SUCCESS:
      return {
        ...state,
        favouriteData: {
          ...state.favouriteData,
          ...data
        }
      };

    default:
      return state;
  }
}
