import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import {
  PARENTAL_CHECK_PIN,
  parentalCheckPinSuccess,
  PARENTALCONTROL_LOAD,
  parentalControlLoadSuccess,
  PARENTAL_SEND_OTP,
  parentalSendOtpSuccess,
  PARENTAL_SET_PIN,
  parentalSetPinSuccess,
  parentalSetPinError,
  CHANGE_CHECK_PIN,
  changeCheckPinSuccess,
  CHANGE_RATING,
  changeRatingSuccess
} from './actions';
import { setError } from '../../redux-root/actions';

export function* checkPin(action) {
  const { payload } = action;

  try {
    const parentalRequest = {
      requestId: uuidv4(),
      pin: payload
    };

    yield call(Client.postData, '/me/parentalcontrol/checkpin', parentalRequest);

    yield put(parentalCheckPinSuccess(true));
  } catch (error) {
    console.log(error);

    yield put(parentalCheckPinSuccess(false));
  }
}

export function* parentalControlLoad() {
  try {
    yield put(parentalControlLoadSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* parentalSendOtp() {
  try {
    const parentalRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'ParentalControlPin'
    };

    yield call(Client.postData, '/me/challenge', parentalRequest);

    yield put(parentalSendOtpSuccess());
  } catch (error) {
    console.log(error);

    // yield put(
    //   setError("Parental Control Title", "Parental Control Message description")
    // );
  }
}

export function* parentalSetPin(action) {
  const { payload } = action;

  try {
    const setRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/parentalcontrol/pin', setRequest);

    yield put(parentalSetPinSuccess());
  } catch (error) {
    // console.log(error);
    
    yield put(parentalSetPinError());
  }
}

export function* changeCheckPin(action) {
  const { payload } = action;

  try {
    const parentalRequest = {
      requestId: uuidv4(),
      pin: payload
    };

    yield call(Client.postData, '/me/parentalcontrol/checkpin', parentalRequest);

    yield put(changeCheckPinSuccess(true));
  } catch (error) {
    console.log(error);

    yield put(changeCheckPinSuccess(false));
  }
}

export function* changeRating(action) {
  const { payload } = action;

  try {
    const parentalRequest = {
      requestId: uuidv4(),
      contentRating: payload
    };

    yield call(Client.postData, '/me/parentalcontrol/contentrating', parentalRequest);
    const parentalData = yield call(Client.getData, '/me/parentalcontrol');

    yield put(changeRatingSuccess(parentalData.data));
  } catch (error) {
    // console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchCheckPin() {
  yield takeEvery(PARENTAL_CHECK_PIN, checkPin);
}

function* watchParentalControlLoad() {
  yield takeEvery(PARENTALCONTROL_LOAD, parentalControlLoad);
}

function* watchParentalOtp() {
  yield takeEvery(PARENTAL_SEND_OTP, parentalSendOtp);
}

function* watchParentalSetPin() {
  yield takeEvery(PARENTAL_SET_PIN, parentalSetPin);
}

function* watchChangeCheckPin() {
  yield takeEvery(CHANGE_CHECK_PIN, changeCheckPin);
}

function* watchChangeRating() {
  yield takeEvery(CHANGE_RATING, changeRating);
}

export function* parentalSaga() {
  yield fork(watchCheckPin);
  yield fork(watchParentalControlLoad);
  yield fork(watchParentalOtp);
  yield fork(watchParentalSetPin);
  yield fork(watchChangeCheckPin);
  yield fork(watchChangeRating);
}
