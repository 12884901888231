import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { WATCHHISTORY_LOAD, watchHistoryLoadSuccess, HISTORY_ADD, historyActionSuccess, HISTORY_SET, HISTORY_DELETE, HISTORY_DELETE_ALL } from './actions';
import { setError } from '../../redux-root/actions';

export function* watchHistoryLoad() {
  try {
    const nowDate = new Date();

    const [historyChannelData, historyMovieData, historyShowData] = yield all([
      call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`),
      call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`),
      call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`)
    ]);

    yield put(watchHistoryLoadSuccess(historyChannelData.data, historyMovieData.data, historyShowData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* historyAdd(action) {
  const { payload } = action;

  try {
    let historyRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
    };

    if (payload.time) {
      historyRequest.position = payload.time;
    }

    yield call(Client.postData, `/me/history${payload.url}`, historyRequest);

    let historyData = {};

    const nowDate = new Date();

    if (payload.url.includes('/movies')) {
      const historyMovieData = yield call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`);
      historyData.movie = historyMovieData.data;
    } else if (payload.url.includes('/shows')) {
      const historyShowData = yield call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`);
      historyData.show = historyShowData.data;
    } else if (payload.url.includes('/channels')) {
      const historyChannelData = yield call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`);
      historyData.channel = historyChannelData.data;
    }

    yield put(historyActionSuccess(historyData));
  } catch (error) {
    console.log(error);
  }
}

export function* historySet(action) {
  const { payload } = action;

  try {
    let historyRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d')
    };

    if (payload.time) {
      historyRequest.position = payload.time;
    }

    yield call(Client.putData, `/me/history${payload.url}`, historyRequest);

    let historyData = {};

    const nowDate = new Date();

    if (payload.url.includes('/movies')) {
      const historyMovieData = yield call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`);
      historyData.movie = historyMovieData.data;
    } else if (payload.url.includes('/shows')) {
      const historyShowData = yield call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`);
      historyData.show = historyShowData.data;
    } else if (payload.url.includes('/channels')) {
      const historyChannelData = yield call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`);
      historyData.channel = historyChannelData.data;
    }

    yield put(historyActionSuccess(historyData));
  } catch (error) {
    console.log(error);
  }
}

export function* historyDelete(action) {
  const { payload } = action;

  try {
    let historyRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d')
    };

    yield call(Client.deleteData, `/me/history${payload.url}`, historyRequest);

    let historyData = {};

    const nowDate = new Date();

    if (payload.url.includes('/movies')) {
      const historyMovieData = yield call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`);
      historyData.movie = historyMovieData.data;
    } else if (payload.url.includes('/shows')) {
      const historyShowData = yield call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`);
      historyData.show = historyShowData.data;
    } else if (payload.url.includes('/channels')) {
      const historyChannelData = yield call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`);
      historyData.channel = historyChannelData.data;
    }

    yield put(historyActionSuccess(historyData));
  } catch (error) {
    console.log(error);
  }
}

export function* historyDeleteAll() {
  try {
    yield all([
      call(Client.deleteData, `/me/history/shows`),
      call(Client.deleteData, `/me/history/movies`),
      call(Client.deleteData, `/me/history/channels`)
    ])

    const nowDate = new Date();

    const [historyShowData, historyMovieData, historyChannelData] = yield all([
      call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`),
      call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`),
      call(Client.getData, `/me/history/channels?t=${nowDate.toISOString()}`)
    ]);

    const historyData = {
      movie: historyMovieData.data,
      show: historyShowData.data,
      channel: historyChannelData.data,
    };

    yield put(historyActionSuccess(historyData));
  } catch (error) {
    console.log(error);
  }
}

function* watchWatchHistoryLoad() {
  yield takeEvery(WATCHHISTORY_LOAD, watchHistoryLoad);
}

function* watchWatchHistoryAdd() {
  yield takeEvery(HISTORY_ADD, historyAdd);
}

function* watchWatchHistorySet() {
  yield takeEvery(HISTORY_SET, historySet);
}

function* watchWatchHistoryDelete() {
  yield takeEvery(HISTORY_DELETE, historyDelete);
}

function* watchWatchHistoryDeleteAll() {
  yield takeEvery(HISTORY_DELETE_ALL, historyDeleteAll);
}

export function* watchHistorySaga() {
  yield fork(watchWatchHistoryLoad);
  yield fork(watchWatchHistoryAdd);
  yield fork(watchWatchHistorySet);
  yield fork(watchWatchHistoryDelete);
  yield fork(watchWatchHistoryDeleteAll);
}
