import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { LINK_SERVICE, linkServiceSuccess, linkServiceError } from './actions';
import { setError } from '../../redux-root/actions';

export function* linkService(action) {
  const { payload } = action;

  try {
    const serviceRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/services', serviceRequest);

    yield put(linkServiceSuccess());
  } catch (error) {
    // console.log(error);

    yield put(linkServiceError());
  }
}

function* watchLinkService() {
  yield takeEvery(LINK_SERVICE, linkService);
}

export function* linkSaga() {
  yield fork(watchLinkService);
}
