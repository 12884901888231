export const SUBSCRIPTIONS_LOAD = "subscriptions/on_load";
export const subscriptionsLoad = () => ({
  type: SUBSCRIPTIONS_LOAD,
});

export const SUBSCRIPTIONS_LOAD_SUCCESS = "subscriptions/on_load_success";
export const subscriptionsLoadSuccess = (data, partnerApps) => ({
  type: SUBSCRIPTIONS_LOAD_SUCCESS,
  payload: data,
  partnerApps: partnerApps
});


