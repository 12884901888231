import {
  ACCOUNTDETAIL_LOAD,
  ACCOUNTDETAIL_LOAD_SUCCESS,
  PASSWORD_CHECK,
  PASSWORD_CHECK_SUCCESS,
  PASSWORD_CHECK_ERROR,
  EMAIL_OTP_SUCCESS,
  EMAIL_CHANGE,
  EMAIL_CHANGE_SUCCESS,
  EMAIL_CHANGE_ERROR,
  PASSWORD_CHANGE_SUCCESS,
  FORGOT_OTP,
  FORGOT_OTP_SUCCESS,
  FORGOT_CHANGE,
  FORGOT_CHANGE_SUCCESS,
  FORGOT_CHANGE_ERROR,
  EMAIL_CHECK_OTP_SUCCESS,
  EMAIL_VERIFY_OTP,
  EMAIL_VERIFY_OTP_SUCCESS,
  EMAIL_VERIFY_OTP_ERROR,
  MOBILE_SENT_OTP_SUCCESS,
  MOBILE_CHANGE_CHECK,
  MOBILE_CHANGE_CHECK_SUCCESS,
  MOBILE_CHANGE_CHECK_ERROR,
  ACCOUNT_LINK_SERVICE,
  ACCOUNT_LINK_SERVICE_SUCCESS,
  ACCOUNT_LINK_SERVICE_ERROR,
  ACCOUNT_UNLINK_SERVICE_SUCCESS
} from "./actions";

const initialState = {
  loaded: false,
  services: null,
  passwordSuccess: false,
  passwordError: false,
  emailOtpSent: false,
  emailSuccess: false,
  emailError: false,
  passwordValue: null,
  passChangeSuccess: false,
  forgotOtpSent: false,
  forgotError: false,
  forgotSuccess: false,
  emailCheckOtpSent: false,
  emailCheckOtpVerify: false,
  emailCheckOtpError: false,
  mobileOtpSent: false,
  mobileCheckOtpVerify: false,
  mobileCheckOtpError: false,
  linkServiceSuccess: false,
  linkServiceError: false
};

export function accountDetailReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case ACCOUNTDETAIL_LOAD:
      return {
        ...initialState
      };

    case ACCOUNTDETAIL_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        services: action.services
      };

    case PASSWORD_CHECK:
      return {
        ...state,
        passwordValue: data,
        passwordError: false
      };

    case PASSWORD_CHECK_SUCCESS:
      return {
        ...state,
        passwordSuccess: true
      };

    case PASSWORD_CHECK_ERROR:
      return {
        ...state,
        passwordError: true
      };

    case EMAIL_OTP_SUCCESS:
      return {
        ...state,
        emailOtpSent: true
      };

    case EMAIL_CHANGE:
      return {
        ...state,
        emailError: false
      };

    case EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        emailSuccess: true
      };

    case EMAIL_CHANGE_ERROR:
      return {
        ...state,
        emailError: true
      };

    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        passChangeSuccess: true
      };

    case FORGOT_OTP:
      return {
        ...state,
        forgotOtpSent: false,
        forgotError: false,
        forgotSuccess: false
      };

    case FORGOT_OTP_SUCCESS:
      return {
        ...state,
        forgotOtpSent: true,
      };

    case FORGOT_CHANGE:
      return {
        ...state,
        forgotError: false
      };

    case FORGOT_CHANGE_SUCCESS:
      return {
        ...state,
        forgotSuccess: true
      };

    case FORGOT_CHANGE_ERROR:
      return {
        ...state,
        forgotError: true
      };

    case EMAIL_CHECK_OTP_SUCCESS:
      return {
        ...state,
        emailCheckOtpSent: true,
      };

    case EMAIL_VERIFY_OTP:
      return {
        ...state,
        emailCheckOtpError: false
      };

    case EMAIL_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        emailCheckOtpVerify: true
      };

    case EMAIL_VERIFY_OTP_ERROR:
      return {
        ...state,
        emailCheckOtpError: true
      };

    case MOBILE_CHANGE_CHECK:
      return {
        ...state,
        mobileCheckOtpError: false
      };

    case MOBILE_CHANGE_CHECK_SUCCESS:
      return {
        ...state,
        mobileCheckOtpVerify: true
      };

    case MOBILE_CHANGE_CHECK_ERROR:
      return {
        ...state,
        mobileCheckOtpError: true,
        mobileOtpSent: false,
      };

    case MOBILE_SENT_OTP_SUCCESS:
      return {
        ...state,
        mobileOtpSent: true,
        emailCheckOtpVerify: false,
        mobileCheckOtpError: false,
      };

    case ACCOUNT_LINK_SERVICE:
      return {
        ...state,
        linkServiceError: false
      };

    case ACCOUNT_LINK_SERVICE_SUCCESS:
      return {
        ...state,
        linkServiceSuccess: true
      };

    case ACCOUNT_LINK_SERVICE_ERROR:
      return {
        ...state,
        linkServiceError: true
      };

    case ACCOUNT_UNLINK_SERVICE_SUCCESS:
      return {
        ...state,
        services: data
      };

    default:
      return state;
  }
}
