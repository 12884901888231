import { takeEvery, put, call, fork, all } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { Client } from "../../utils/client";
import { FAVOURITE_LOAD, favouriteLoadSuccess, FAVOURITE_ADD, FAVOURITE_REMOVE, favouriteActionSuccess, FAVOURITE_CHANNEL_LOAD, favouriteChannelLoadSuccess } from "./actions";
import { setError } from "../../redux-root/actions";

export function* favouriteLoad() {
  try {
    const [favouriteChannelData, favouriteMovieData, favouriteShowData] = yield all([
      call(Client.getData, '/me/playlists/handles/watchlater/channels'),
      call(Client.getData, '/me/playlists/handles/watchlater/movies'),
      call(Client.getData, '/me/playlists/handles/watchlater/shows')
    ]);

    const favouriteData = {
      channel: favouriteChannelData.data,
      movie: favouriteMovieData.data,
      show: favouriteShowData.data,
    };

    yield put(favouriteLoadSuccess(favouriteData));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* favouriteAdd(action) {
  const { payload } = action;

  try {
    const addRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d')
    };

    const addData = yield call(Client.postData, `/me/playlists/handles/watchlater/${payload}`, addRequest);

    if (addData.status && addData.status === 200) {
      yield put(favouriteActionSuccess(payload, true, true));
    } else {
      yield put(favouriteActionSuccess(payload, false, true));
    }
  } catch (error) {
    console.log(error);

    yield put(favouriteActionSuccess(payload, false, true));
  }
}

export function* favouriteRemove(action) {
  const { payload } = action;

  try {
    const removeRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d')
    };

    const removeData = yield call(Client.deleteData, `/me/playlists/handles/watchlater/${payload}`, removeRequest);

    if (removeData.status && removeData.status === 200) {
      yield put(favouriteActionSuccess(payload, true, false));
    } else {
      yield put(favouriteActionSuccess(payload, false, false));
    }
  } catch (error) {
    console.log(error);

    yield put(favouriteActionSuccess(payload, false, false));
  }
}

export function* favouriteChannelLoad() {
  try {
    const favouriteChannelData = yield call(Client.getData, '/me/playlists/handles/watchlater/channels');

    const favouriteData = {
      channel: favouriteChannelData.data
    };

    yield put(favouriteChannelLoadSuccess(favouriteData));
  } catch (error) {
    console.log(error);

    // yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchFavouriteLoad() {
  yield takeEvery(FAVOURITE_LOAD, favouriteLoad);
}

function* watchFavouriteAdd() {
  yield takeEvery(FAVOURITE_ADD, favouriteAdd);
}

function* watchFavouriteRemove() {
  yield takeEvery(FAVOURITE_REMOVE, favouriteRemove);
}

function* watchFavouriteChannelLoad() {
  yield takeEvery(FAVOURITE_CHANNEL_LOAD, favouriteChannelLoad);
}

export function* favouriteSaga() {
  yield fork(watchFavouriteLoad);
  yield fork(watchFavouriteAdd);
  yield fork(watchFavouriteRemove);
  yield fork(watchFavouriteChannelLoad);
}
