import {
  GUIDE_LOAD,
  GUIDE_LOAD_SUCCESS,
  GUIDE_FILTER_DATE,
  GUIDE_FILTER_DATE_SUCCESS,
  GUIDE_GET_PACKAGE,
  GUIDE_GET_PACKAGE_SUCCESS,
  GUIDE_CLEAR_PACKAGE,
  GUIDE_REFRESH,
  GUIDE_REFRESH_SUCCESS
} from './actions';

const initialState = {
  loaded: false,
  guideData: null,
  filterLoading: false,
  playerGuideData: null,
  packageData: null
};

export function guideReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case GUIDE_LOAD:
    case GUIDE_REFRESH:
      return {
        ...state,
        loaded: false
      };

    case GUIDE_LOAD_SUCCESS:
    case GUIDE_REFRESH_SUCCESS:
      return {
        ...state,
        loaded: true,
        guideData: data,
        playerGuideData: data
      };

    case GUIDE_FILTER_DATE:
      return {
        ...state,
        filterLoading: true
      };

    case GUIDE_FILTER_DATE_SUCCESS:
      return {
        ...state,
        filterLoading: false,
        guideData: data
      };

    case GUIDE_GET_PACKAGE:
    case GUIDE_CLEAR_PACKAGE:
      return {
        ...state,
        packageData: null
      }

    case GUIDE_GET_PACKAGE_SUCCESS:
      return {
        ...state,
        packageData: data
      }

    default:
      return state;
  }
}
