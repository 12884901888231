export const PARENTAL_CHECK_PIN = 'parental/check_pin';
export const parentalCheckPin = (data) => ({
  type: PARENTAL_CHECK_PIN,
  payload: data
});

export const PARENTAL_CHECK_PIN_SUCCESS = 'parental/check_pin_success';
export const parentalCheckPinSuccess = (data) => ({
  type: PARENTAL_CHECK_PIN_SUCCESS,
  payload: data
});

export const PARENTALCONTROL_LOAD = "parental/on_load";
export const parentalControlLoad = () => ({
  type: PARENTALCONTROL_LOAD,
});

export const PARENTALCONTROL_LOAD_SUCCESS = "parental/on_load_success";
export const parentalControlLoadSuccess = (data) => ({
  type: PARENTALCONTROL_LOAD_SUCCESS,
  payload: data
});

export const PARENTAL_SEND_OTP = "parental/send_otp";
export const parentalSendOtp = () => ({
  type: PARENTAL_SEND_OTP,
});

export const PARENTAL_SEND_OTP_SUCCESS = "parental/send_otp_success";
export const parentalSendOtpSuccess = () => ({
  type: PARENTAL_SEND_OTP_SUCCESS
});

export const PARENTAL_SET_PIN = "parental/set_pin";
export const parentalSetPin = (data) => ({
  type: PARENTAL_SET_PIN,
  payload: data
});

export const PARENTAL_SET_PIN_SUCCESS = "parental/set_pin_success";
export const parentalSetPinSuccess = () => ({
  type: PARENTAL_SET_PIN_SUCCESS
});

export const PARENTAL_SET_PIN_ERROR = "parental/set_pin_error";
export const parentalSetPinError = () => ({
  type: PARENTAL_SET_PIN_ERROR
});

export const CHANGE_CHECK_PIN = 'parental/change_check_pin';
export const changeCheckPin = (data) => ({
  type: CHANGE_CHECK_PIN,
  payload: data
});

export const CHANGE_CHECK_PIN_SUCCESS = 'parental/change_check_pin_success';
export const changeCheckPinSuccess = (data) => ({
  type: CHANGE_CHECK_PIN_SUCCESS,
  payload: data
});

export const CHANGE_CHECK_PIN_RESET = 'parental/change_check_pin_reset';
export const changeCheckPinReset = () => ({
  type: CHANGE_CHECK_PIN_RESET
});

export const CHANGE_RATING = 'parental/change_rating';
export const changeRating = (data) => ({
  type: CHANGE_RATING,
  payload: data
});

export const CHANGE_RATING_SUCCESS = 'parental/change_rating_success';
export const changeRatingSuccess = (data) => ({
  type: CHANGE_RATING_SUCCESS,
  payload: data
});
