import { fork } from "redux-saga/effects";
import { appSaga } from "../app/saga";
import { menuSaga } from "../components/Menu/saga";
import { loginSaga } from "../page/Login/saga";
import { homeSaga } from "../page/Home/saga";
import { subscriptionsSaga } from "../page/Subscriptions/saga";
import { subscriptionsDetailSaga } from "../page/SubscriptionsDetail/saga";
import { favouriteSaga } from "../page/Favourite/saga";
import { watchHistorySaga } from "../page/WatchHistory/saga";
import { detailsSaga } from "../page/Details/saga";
import { searchSaga } from "../page/Search/saga";
import { ondemandSaga } from "../page/OnDemand/saga";
import { playerSaga } from "../page/Player/saga";
import { parentalSaga } from "../page/Parental/saga";
import { aboutUsSaga } from "../page/AboutUs/saga";
import { accountDetailSaga } from "../page/AccountDetail/saga";
import { guideSaga } from "../page/Guide/saga";
import { setupSaga } from "../page/Setup/saga";
import { linkSaga } from "../page/Link/saga";
import { termsSaga } from "../page/Terms/saga";
import { drSaga } from "../page/DR/saga";

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(menuSaga);
  yield fork(loginSaga);
  yield fork(homeSaga);
  yield fork(subscriptionsSaga);
  yield fork(subscriptionsDetailSaga);
  yield fork(favouriteSaga);
  yield fork(watchHistorySaga);
  yield fork(detailsSaga);
  yield fork(searchSaga);
  yield fork(ondemandSaga);
  yield fork(playerSaga);
  yield fork(parentalSaga);
  yield fork(aboutUsSaga);
  yield fork(accountDetailSaga);
  yield fork(guideSaga);
  yield fork(setupSaga);
  yield fork(linkSaga);
  yield fork(termsSaga);
  yield fork(drSaga);
}
