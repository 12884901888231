import {
  STREAM_LOAD,
  STREAM_LOAD_SUCCESS,
  STREAM_LOAD_CHANNEL,
  STREAM_LOAD_CHANNEL_SUCCESS,
  STREAM_CHECK_CONCURRENT,
  STREAM_CHECK_CONCURRENT_FULL,
  LOAD_OTHER_SUCCESS,
  CHANNEL_SEARCH,
  CHANNEL_SEARCH_SUCCESS,
  CHANNEL_SEARCH_ERROR
} from './actions';
import {
  CLEAR_STREAM_VOD,
  FAVOURITE_CHANNEL_LOAD_SUCCESS,
  SET_AUTHENTICATED
} from '../../redux-root/actions';

const initialState = {
  streamData: null,
  concurrentFull: false,
  liveStreamData: null,
  otherLoaded: false,
  searchError: false,
  searchData: null,
  favLoaded: false
};

export function playerReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case CLEAR_STREAM_VOD:
      return {
        ...state,
        streamData: null,
        concurrentFull: false
      };

    case STREAM_LOAD:
      return {
        ...state,
        streamData: null
      }

    case STREAM_LOAD_SUCCESS:
      return {
        ...state,
        streamData: data
      };

    case STREAM_CHECK_CONCURRENT:
      return {
        ...state,
        concurrentFull: false
      };

    case STREAM_CHECK_CONCURRENT_FULL:
      return {
        ...state,
        concurrentFull: true
      };

    case STREAM_LOAD_CHANNEL:
      return {
        ...state,
        liveStreamData: null,
        otherLoaded: false,
        searchData: null,
        searchError: false,
        favLoaded: false
      };

    case STREAM_LOAD_CHANNEL_SUCCESS:
      return {
        ...state,
        liveStreamData: {
          channel: data,
          stream: action.stream
        }
      };

    case LOAD_OTHER_SUCCESS:
      return {
        ...state,
        otherLoaded: true
      }

    case CHANNEL_SEARCH:
      return {
        ...state,
        searchData: null,
        searchError: false
      }

    case CHANNEL_SEARCH_SUCCESS:
      return {
        ...state,
        searchData: data
      }

    case CHANNEL_SEARCH_ERROR:
      return {
        ...state,
        searchError: true
      }

    case FAVOURITE_CHANNEL_LOAD_SUCCESS:
      return {
        ...state,
        favLoaded: true
      };

    case SET_AUTHENTICATED:
      if (!data) {
        return {
          ...initialState
        };
      }

      return {
        ...state
      };

    default:
      return state;
  }
}
