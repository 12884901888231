export const STREAM_LOAD = 'player/load';
export const streamLoad = (url, free) => ({
  type: STREAM_LOAD,
  payload: url,
  free: free
});

export const STREAM_LOAD_SUCCESS = 'player/load_success';
export const streamLoadSuccess = (data) => ({
  type: STREAM_LOAD_SUCCESS,
  payload: data
});

export const STREAM_CHECK_CONCURRENT = 'player/check_concurrent';
export const streamCheckConcurrent = (type, id) => ({
  type: STREAM_CHECK_CONCURRENT,
  payload: {
    type: type,
    id: id
  }
});

export const STREAM_CHECK_CONCURRENT_FULL = 'player/check_concurrent_full';
export const streamCheckConcurrentFull = () => ({
  type: STREAM_CHECK_CONCURRENT_FULL
});

export const STREAM_LOAD_CHANNEL = 'player/load_channel';
export const streamLoadChannel = (id, free) => ({
  type: STREAM_LOAD_CHANNEL,
  payload: id,
  free: free
});

export const STREAM_LOAD_CHANNEL_SUCCESS = 'player/load_channel_success';
export const streamLoadChannelSuccess = (data, stream) => ({
  type: STREAM_LOAD_CHANNEL_SUCCESS,
  payload: data,
  stream: stream
});

export const LOAD_OTHER = 'player/load_other';
export const loadOther = () => ({
  type: LOAD_OTHER
});

export const LOAD_OTHER_SUCCESS = 'player/load_other_success';
export const loadOtherSuccess = (data, favourite) => ({
  type: LOAD_OTHER_SUCCESS,
  channel: data,
  favourite: favourite
});

export const CHANNEL_SEARCH = 'player/channel_search';
export const channelSearch = (number) => ({
  type: CHANNEL_SEARCH,
  payload: number
});

export const CHANNEL_SEARCH_SUCCESS = 'player/channel_search_success';
export const channelSearchSuccess = (data) => ({
  type: CHANNEL_SEARCH_SUCCESS,
  payload: data
});

export const CHANNEL_SEARCH_ERROR = 'player/channel_search_error';
export const channelSearchError = () => ({
  type: CHANNEL_SEARCH_ERROR
});
