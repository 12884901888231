import { combineReducers } from "redux";
import { appReducer } from "../app/reducer";
import { menuReducer } from "../components/Menu/reducer";
import { loginReducer } from "../page/Login/reducer";
import { homeReducer } from "../page/Home/reducer";
import { subscriptionsReducer } from "../page/Subscriptions/reducer";
import { subscriptionsDetailReducer } from "../page/SubscriptionsDetail/reducer";
import { favouriteReducer } from "../page/Favourite/reducer";
import { detailsReducer } from "../page/Details/reducer";
import { searchReducer } from "../page/Search/reducer";
import { ondemandReducer } from "../page/OnDemand/reducer";
import { watchHistoryReducer } from "../page/WatchHistory/reducer";
import { playerReducer } from "../page/Player/reducer";
import { parentalReducer } from "../page/Parental/reducer";
import { aboutUsReducer } from "../page/AboutUs/reducer";
import { accountDetailReducer } from "../page/AccountDetail/reducer";
import { guideReducer } from "../page/Guide/reducer";
import { setupReducer } from "../page/Setup/reducer";
import { linkReducer } from "../page/Link/reducer";
import { termsReducer } from "../page/Terms/reducer";
import { drReducer } from "../page/DR/reducer";

export default combineReducers({
  app: appReducer,
  menu: menuReducer,
  login: loginReducer,
  home: homeReducer,
  favourite: favouriteReducer,
  subscriptions: subscriptionsReducer,
  subscriptionsDetail: subscriptionsDetailReducer,
  watchHistory: watchHistoryReducer,
  details: detailsReducer,
  search: searchReducer,
  ondemand: ondemandReducer,
  player: playerReducer,
  parental: parentalReducer,
  aboutUsReducer: aboutUsReducer,
  accountDetail: accountDetailReducer,
  epgGuide: guideReducer,
  setup: setupReducer,
  link: linkReducer,
  terms: termsReducer,
  dr: drReducer
});
