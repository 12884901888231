export * from "../app/actions";
export * from "../components/Menu/actions";
export * from "../page/Login/actions";
export * from "../page/Home/actions";
export * from "../page/Subscriptions/actions";
export * from "../page/SubscriptionsDetail/actions";
export * from "../page/Favourite/actions";
export * from "../page/WatchHistory/actions";
export * from "../page/Error/actions";
export * from "../page/Details/actions";
export * from "../page/Search/actions";
export * from "../page/OnDemand/actions";
export * from "../page/Player/actions";
export * from "../page/Parental/actions";
export * from "../page/AboutUs/actions";
export * from "../page/AccountDetail/actions";
export * from "../page/Guide/actions";
export * from "../page/Setup/actions";
export * from "../page/Link/actions";
export * from "../page/Terms/actions";
export * from "../page/DR/actions";
