import {
  HOME_LOAD,
  HOME_LOAD_SUCCESS
} from './actions';

const initialState = {
  homeJson: null
};

export function homeReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case HOME_LOAD:
      return {
        ...state,
        homeJson: null
      };

    case HOME_LOAD_SUCCESS:
      return {
        ...state,
        homeJson: data
      };

    default:
      return state;
  }
}
