import {
  TV_CODE_LOAD_SUCCESS,
  TV_CHECK_CODE_SUCCESS,
  LOGIN_CREATE_CUSTOMER,
  LOGIN_CREATE_CUSTOMER_SUCCESS,
  FORGOT_SEND_OTP,
  FORGOT_SEND_OTP_SUCCESS,
  FORGOT_SEND_OTP_ERROR,
  RESET_PASS,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
  RESET_ALL
} from './actions';
import {
  SET_AUTHENTICATED
} from '../../redux-root/actions';

const initialState = {
  tvCode: null,
  tvLink: null,
  createSuccess: false,
  forgotOtpSent: false,
  forgotOtpError: false,
  resetSuccess: false,
  resetError: false
};

export function loginReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        tvCode: null,
        tvLink: null
      };

    case TV_CODE_LOAD_SUCCESS:
      return {
        ...state,
        tvCode: data
      };

    case TV_CHECK_CODE_SUCCESS:
      return {
        ...state,
        tvLink: data
      };

    case LOGIN_CREATE_CUSTOMER:
      return {
        ...state,
        createSuccess: false
      };

    case LOGIN_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createSuccess: true
      };

    case FORGOT_SEND_OTP:
      return {
        ...state,
        forgotOtpError: false
      };

    case FORGOT_SEND_OTP_SUCCESS:
      return {
        ...state,
        forgotOtpSent: true
      };

    case FORGOT_SEND_OTP_ERROR:
      return {
        ...state,
        forgotOtpError: true
      };

    case RESET_PASS:
      return {
        ...state,
        resetError: false
      };

    case RESET_PASS_SUCCESS:
      return {
        ...state,
        resetSuccess: true
      };

    case RESET_PASS_ERROR:
      return {
        ...state,
        resetError: true
      };

    case RESET_ALL:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
