import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import {
  ACCOUNTDETAIL_LOAD,
  accountDetailLoadSuccess,
  PASSWORD_CHECK,
  passwordCheckSuccess,
  passwordCheckError,
  EMAIL_OTP,
  emailOtpSuccess,
  EMAIL_CHANGE,
  emailChangeSuccess,
  emailChangeError,
  PASSWORD_CHANGE,
  passwordChangeSuccess,
  FORGOT_OTP,
  forgotOtpSuccess,
  FORGOT_CHANGE,
  forgotChangeSuccess,
  forgotChangeError,
  EMAIL_CHECK_OTP,
  emailCheckOtpSuccess,
  EMAIL_VERIFY_OTP,
  emailVerifyOtpSuccess,
  emailVerifyOtpError,
  MOBILE_SENT_OTP,
  mobileSentOtpSuccess,
  MOBILE_CHANGE_CHECK,
  mobileChangeCheckSuccess,
  mobileChangeCheckError,
  ACCOUNT_LINK_SERVICE,
  accountLinkServiceSuccess,
  accountLinkServiceError,
  ACCOUNT_UNLINK_SERVICE,
  accountUnlinkServiceSuccess
} from "./actions";
import { setError } from "../../redux-root/actions";

export function* accountDetailLoad() {
  try {
    const profileData = yield call(Client.getData, '/me');
    const serviceData = yield call(Client.getData, '/me/services');

    yield put(accountDetailLoadSuccess(profileData.data, serviceData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* passwordCheck(action) {
  const { payload } = action;

  try {
    const accountRequest = {
      requestId: uuidv4(),
      secret: payload
    };

    yield call(Client.postData, '/me/checksecret', accountRequest);

    yield put(passwordCheckSuccess());
  } catch (error) {
    console.log(error);

    yield put(passwordCheckError());
  }
}

export function* emailOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'EmailVerificationCode',
      email: payload
    };

    yield call(Client.postData, '/me/challenge', otpRequest);

    yield put(emailOtpSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* emailChange(action) {
  const { payload } = action;

  try {
    const accountRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.putData, '/me/email', accountRequest);

    yield put(emailChangeSuccess());
  } catch (error) {
    console.log(error);

    yield put(emailChangeError());
  }
}

export function* passwordChange(action) {
  const { payload } = action;

  try {
    const passRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/changesecret', passRequest);

    yield put(passwordChangeSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* forgotOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'ResetPassword'
    };

    yield call(Client.postData, '/me/challenge', otpRequest);

    yield put(forgotOtpSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* forgotChange(action) {
  const { payload } = action;

  try {
    const changeRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/resetsecret', changeRequest);

    yield put(forgotChangeSuccess());
  } catch (error) {
    console.log(error);

    yield put(forgotChangeError());
  }
}

export function* emailCheckOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'EmailVerificationCode',
      email: payload
    };

    yield call(Client.postData, '/me/challenge', otpRequest);

    yield put(emailCheckOtpSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* emailVerifyOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'EmailVerificationCode',
      answer: payload
    };

    yield call(Client.putData, '/me/challenge', otpRequest);

    yield put(emailVerifyOtpSuccess());
  } catch (error) {
    console.log(error);

    yield put(emailVerifyOtpError());
  }
}

export function* mobileSentOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'UpdateMobilePhone',
      mobilePhone: payload
    };

    yield call(Client.postData, '/me/challenge', otpRequest);

    yield put(mobileSentOtpSuccess());
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* mobileChangeCheck(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/mobilephone', otpRequest);

    yield put(mobileChangeCheckSuccess());
  } catch (error) {
    console.log(error);

    yield put(mobileChangeCheckError());
  }
}

export function* accountLinkService(action) {
  const { payload } = action;

  try {
    const serviceRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.postData, '/me/services', serviceRequest);

    yield put(accountLinkServiceSuccess());
  } catch (error) {
    // console.log(error);

    yield put(accountLinkServiceError());
  }
}

export function* accountUnlinkService(action) {
  const { payload } = action;

  try {
    const serviceRequest = {
      requestId: uuidv4(),
      ...payload
    };

    yield call(Client.deleteData, '/me/services', serviceRequest);
    const serviceData = yield call(Client.getData, '/me/services');

    yield put(accountUnlinkServiceSuccess(serviceData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchAccountDetailLoad() {
  yield takeEvery(ACCOUNTDETAIL_LOAD, accountDetailLoad);
}

function* watchPasswordCheck() {
  yield takeEvery(PASSWORD_CHECK, passwordCheck);
}

function* watchEmailOtp() {
  yield takeEvery(EMAIL_OTP, emailOtp);
}

function* watchEmailChange() {
  yield takeEvery(EMAIL_CHANGE, emailChange);
}

function* watchPasswordChange() {
  yield takeEvery(PASSWORD_CHANGE, passwordChange);
}

function* watchForgotOtp() {
  yield takeEvery(FORGOT_OTP, forgotOtp);
}

function* watchForgotChange() {
  yield takeEvery(FORGOT_CHANGE, forgotChange);
}

function* watchEmailCheckOtp() {
  yield takeEvery(EMAIL_CHECK_OTP, emailCheckOtp);
}

function* watchEmailVerifyOtp() {
  yield takeEvery(EMAIL_VERIFY_OTP, emailVerifyOtp);
}

function* watchMobileSentOtp() {
  yield takeEvery(MOBILE_SENT_OTP, mobileSentOtp);
}

function* watchMobileChangeCheck() {
  yield takeEvery(MOBILE_CHANGE_CHECK, mobileChangeCheck);
}

function* watchAccountLinkService() {
  yield takeEvery(ACCOUNT_LINK_SERVICE, accountLinkService);
}

function* watchAccountUnlinkService() {
  yield takeEvery(ACCOUNT_UNLINK_SERVICE, accountUnlinkService);
}

export function* accountDetailSaga() {
  yield fork(watchAccountDetailLoad);
  yield fork(watchPasswordCheck);
  yield fork(watchEmailOtp);
  yield fork(watchEmailChange);
  yield fork(watchPasswordChange);
  yield fork(watchForgotOtp);
  yield fork(watchForgotChange);
  yield fork(watchEmailCheckOtp);
  yield fork(watchEmailVerifyOtp);
  yield fork(watchMobileSentOtp);
  yield fork(watchMobileChangeCheck);
  yield fork(watchAccountLinkService);
  yield fork(watchAccountUnlinkService);
}
