export const WATCHHISTORY_LOAD = "watchHistory/on_load";
export const watchHistoryLoad = () => ({
  type: WATCHHISTORY_LOAD,
});

export const WATCHHISTORY_LOAD_SUCCESS = "watchHistory/on_load_success";
export const watchHistoryLoadSuccess = (channel, movie, show) => ({
  type: WATCHHISTORY_LOAD_SUCCESS,
  channel: channel,
  movie: movie,
  show: show
});

export const HISTORY_ADD = "watchHistory/add_to";
export const historyAdd = (data, time) => ({
  type: HISTORY_ADD,
  payload: {
    url: data,
    time: time
  }
});

export const HISTORY_SET = "watchHistory/set_to";
export const historySet = (data, time) => ({
  type: HISTORY_SET,
  payload: {
    url: data,
    time: time
  }
});

export const HISTORY_DELETE = "watchHistory/delete_specific";
export const historyDelete = (data) => ({
  type: HISTORY_DELETE,
  payload: data
});

export const HISTORY_DELETE_ALL = "watchHistory/delete_all";
export const historyDeleteAll = () => ({
  type: HISTORY_DELETE_ALL
});

export const HISTORY_ACTION_SUCCESS = "watchHistory/action_success";
export const historyActionSuccess = (history) => ({
  type: HISTORY_ACTION_SUCCESS,
  history: history
});
