import {
  ONDEMAND_LOAD_SUCCESS,
  ONDEMAND_FILTER,
  ONDEMAND_FILTER_SUCCESS,
} from "./actions";

const initialState = {
  svod: null,
  pageData: null,
  filterLoad: false,
  list: null,
};

export function ondemandReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case ONDEMAND_LOAD_SUCCESS:
      return {
        ...state,
        svod: data,
        pageData: action.pageData,
      };

    default:
      return state;

    case ONDEMAND_FILTER:
      return {
        ...state,
        filterLoad: true,
      };

    case ONDEMAND_FILTER_SUCCESS:
      return {
        ...state,
        list: data,
        filterLoad: false,
      };
  }
}
