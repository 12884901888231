import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lottie from "lottie-react";
import _ from 'lodash';
import styles from './index.module.css';

import moreIcon from '../../assets/icons/mobileMenu/more.svg';
import triangleIcon from '../../assets/icons/mobileMenu/triangle.svg';

import { useNavigate, useLocation } from "react-router-dom";
import { toggleMenu, setModal } from '../../redux-root/actions'
import Text from '../Text';

import data from '../../assets/icons/mobileMenu/data.svg';
import faq from '../../assets/icons/mobileMenu/faq.svg';
import feedback from '../../assets/icons/mobileMenu/feedback.svg';
import helpaccountdetails from '../../assets/icons/mobileMenu/helpaccountdetails.svg';
import about from '../../assets/icons/mobileMenu/About.svg';
import linkdevice from '../../assets/icons/mobileMenu/linkdevice.svg';
import mydownloads from '../../assets/icons/mobileMenu/mydownloads.svg';
import myfavourites from '../../assets/icons/mobileMenu/myfavourites.svg';
import mysubscriptions from '../../assets/icons/mobileMenu/mysubscriptions.svg';
import parentalcontrol from '../../assets/icons/mobileMenu/parentalcontrol.svg';
import recording from '../../assets/icons/mobileMenu/Recording.svg';
import settingsaccountdetails from '../../assets/icons/mobileMenu/settingsaccountdetails.svg';
import settingsinbox from '../../assets/icons/mobileMenu/settingsinbox.svg';

function NavigationMore(props) {

  const navigate = useNavigate();

  const { setShowMenu, authenticated } = props;

  const myCastList = [
    { title: 'My favourites', icon: myfavourites, path: '/favourite' },
    // { title: 'My downloads', icon: mydownloads, path: "/" }, 
    { title: 'My subscriptions', icon: mysubscriptions, path: "subscriptions" }
  ]

  const settingsList = [
    // { title: 'Inbox', icon: settingsinbox, path: "/" }, 
    { title: 'Account details', icon: settingsaccountdetails, path: "/account-details" },
    // { title: 'Data', icon: data, path: "/" }, 
    { title: 'Parental control', icon: parentalcontrol, path: "/parental-control" },
    // { title: 'Link device', icon: linkdevice, path: "/" }
  ]

  // const remoteList = [{ title: 'Remote recording', icon: recording, path: "/" }]

  // const helpList = [
  //   { title: 'Inbox', icon: about, path: "/" }, 
  //   { title: 'Account details', icon: helpaccountdetails, path: "/" }, 
  //   { title: 'FAQ', icon: faq, path: "/" }, 
  //   { title: 'Feedback', icon: feedback, path: "/" }
  // ]

  const [showMoreList, setShowMoreList] = useState(false)
  const [showRedShade, setShowRedShade] = useState(false)

  const clickMore = () => {
    if (!showMoreList) {
      if (!authenticated) {
        props.setModal(
          'Subscribe only',
          'Please login to watch.',
          'Login',
          () => navigate('/login'),
        );
      } else {
        setShowMoreList(true);
        setShowRedShade(true);
      }
    } else {
      setShowMoreList(false);
      setShowRedShade(false);
    }

  }

  const itemClick = (index, data) => {
    if (index !== undefined) {
      navigate(data.path)
      setShowMenu(false)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={showRedShade ? styles['RedShade'] : ''}></div>
      <div onClick={() => clickMore()} style={{ display: 'flex', alignItems: 'center' }} >
        <div className={showRedShade ? styles['moreIconWhite'] : styles['moreIcon']}><img src={moreIcon} /></div>
        <div className={showRedShade ? styles['moreTextWhite'] : styles['moreText']}>More</div>
        <div className={styles['triangleIcon']}><img src={triangleIcon} /></div>
      </div>
      {showMoreList && <div className={styles['line']}></div>}
      {showMoreList && <div style={{ marginBottom: '94px', position: 'relative' }}>

        <div className={styles['myCast']}>My Cast</div>
        {myCastList.map((data, index) => {
          return <div key={index} className={styles['iconLocation']} onClick={() => itemClick(index, data)}>
            <div><img className={styles['myCastIcon']} src={data.icon} /></div>
            <div className={styles['title']}><Text type="lb28">{data.title}</Text></div>
          </div>
        })}

        <div className={styles['settings']}>Settings</div>
        {settingsList.map((data, index) => {
          return <div key={index} className={styles['iconLocation']} onClick={() => itemClick(index, data)}>
            <div><img className={styles['myCastIcon']} src={data.icon} /></div>
            <div className={styles['title']}>
              <Text type="lb28">{data.title}</Text></div>
          </div>
        })}

        {/* <div className={styles['remote']}>Remote</div>
        {remoteList.map((data, index) => {
          return <div key={index} className={styles['iconLocation']} onClick={() => itemClick(index, data)}>
            <div><img className={styles['myCastIcon']} src={data.icon} /></div>
            <div className={styles['title']}>{data.title}</div>
          </div>
        })} */}

        {/* <div className={styles['help']}>Help</div>
        {helpList.map((data, index) => {
          return <div key={index} className={styles['iconLocation']} onClick={() => itemClick(index, data)}>
            <div><img className={styles['myCastIcon']} src={data.icon} /></div>
            <div className={styles['title']}>{data.title}</div>
          </div>
        })} */}
      </div>}
    </div>
  );
}


const mapStateToProps = state => ({
  collapsed: state.menu.menuCollapsed,
  streamVod: state.app.streamVod,
  prevPath: state.app.prevPath,
  profileData: state.app.profileData,
  authenticated: state.app.authenticated,
  channelData: state.app.channelData,
  authenticated: state.app.authenticated
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleMenu,
      setModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMore);
